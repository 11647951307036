import pluralizer from 'pluralize'

export function kebabCase (s = '') {
  return s.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase().replace(/^-/g, '')
}

export function camelCase (s = '') {
  return s.replace(/(\s|-)./g, v => v.replace(/(\s|-)/g, '').toUpperCase())
}

export function pascalCase (s = '') {
  return capitalize(camelCase(s))
}

export function capitalize (s = '') {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function decapitalize (s = '') {
  return s.charAt(0).toLowerCase() + s.slice(1)
}

export function singularize (s = '') {
  return pluralizer.singular(s)
}

export function pluralize (s = '') {
  return pluralizer.plural(s)
}

export function format(format, obj) {
  if (obj === null || obj === undefined) return ''

  // Convert object keys to lowercase for case-insensitive matching
  const lowerCaseObj = Object.keys(obj).reduce((acc, key) => {
    acc[key.toLowerCase()] = obj[key]
    return acc
  }, {})

  let replacedAtLeastOne = false

  const result = format.replace(/\{(\w+)\}/g, (match, key) => {
    // Convert format string keys to lowercase for case-insensitive matching
    key = key.toLowerCase()

    if (key in lowerCaseObj && lowerCaseObj[key] !== null && lowerCaseObj[key] !== undefined && lowerCaseObj[key].length > 0) {
      replacedAtLeastOne = true
      return lowerCaseObj[key]
    }

    return ''
  })

  return replacedAtLeastOne ? result : null
}

export default {
  kebabCase,
  camelCase,
  pascalCase,
  capitalize,
  decapitalize,
  singularize,
  pluralize,
  format
}
