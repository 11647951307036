import EXPORT from '@/assets/css/config/js-exports.scss'

const scss = {
  base: {
    black: '#000',
    white: '#fff'
  },
  colors: {
    base: {},
    brand: {},
    pastel: {},
    gray: {},
    theme: {}
  },
  shadows: {},
  grid: {},
  vars: {}
}

createSCSS()

export const BREAKPOINTS = {
  mobilefirst: {
    breakpoints: scss.grid['breakpoint-keys'].reduce((breakpoints, bKey) => Object.assign(breakpoints, { [bKey]: scss.grid[`breakpoint-mobilefirst-${bKey}`].value ?? 0 }), {}),
    tablet: {
      key: scss.vars['breakpoint-mobilefirst-tablet-key'],
      value: scss.vars['breakpoint-mobilefirst-tablet'].value
    },
    desktop: {
      key: scss.vars['breakpoint-mobilefirst-desktop-key'],
      value: scss.vars['breakpoint-mobilefirst-desktop'].value
    }
  },
  desktopfirst: {
    breakpoints: scss.grid['breakpoint-keys'].reduce((breakpoints, bKey) => Object.assign(breakpoints, { [bKey]: scss.grid[`breakpoint-desktopfirst-${bKey}`].value ?? 0 }), {}),
    tablet: {
      key: scss.vars['breakpoint-desktopfirst-tablet-key'],
      value: scss.vars['breakpoint-desktopfirst-tablet'].value
    },
    mobile: {
      key: scss.vars['breakpoint-desktopfirst-mobile-key'],
      value: scss.vars['breakpoint-desktopfirst-mobile'].value
    }
  }
}

export default scss

function createSCSS () {
  for (const vKey in EXPORT) {
    const color = vKey.match(/^color-(base|brand|pastel|gray|theme)-/)
    const shadow = vKey.match(/^shadow-/)
    const grid = vKey.match(/^grid-/)
    const base = [].concat(color || [], shadow || [], grid || [])[0]

    const key = vKey.replace(base, '')
    const value = createValue(EXPORT[vKey])

    if (color) {
      scss.colors[color[1]][key] = value
    } else if (shadow) {
      scss.shadows[key] = value
    } else if (grid) {
      scss.grid[key] = value
    } else {
      scss.vars[key] = value
    }
  }

  function createValue (value) {
    return /^\[.*\]$/.test(value) ? valueToArray(value) : valueToNumber(value)

    function valueToArray (v) {
      return v
        .replace(/(^\[|\]$)/g, '')
        .replace(/,\s?/g, ',')
        .split(',')
        .map(valueToNumber)
    }

    function valueToNumber (value) {
      return /^([\d.]){1,}(([a-zA-Z]){2,}|%)$/.test(value) ? { value: parseFloat(value), unit: value.replace(/^\d+/g, '') } : value
    }
  }
}
