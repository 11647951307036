import { subMenuCloseHandler, subMenuHandler } from './utils.js';

// Initialize desktop navigation menu
export const initializeDesktopNav = () => {
  document.querySelectorAll('.main-item').forEach(mainItem => {
    mainItem.addEventListener('mouseenter', () => {
      const bodyElement = document.querySelector('body');
      if (bodyElement.classList.contains('mobile-nav-open')) {
        return;
      }

      const defaultContent = mainItem.querySelector('.default-content');
      const subMenuItems = mainItem.querySelectorAll('.menu-item.has-right-content');

      const mainItemClickHandler = (e) => {
        subMenuCloseHandler(e, mainItem);
      };

      mainItem.addEventListener('click', mainItemClickHandler);

      subMenuItems.forEach(subMenuItem => {
        const enterHandler = subMenuHandler(subMenuItem, defaultContent, true);
        const leaveHandler = subMenuHandler(subMenuItem, defaultContent, false);

        subMenuItem.addEventListener('mouseenter', enterHandler);
        subMenuItem.addEventListener('mouseleave', leaveHandler);

        subMenuItem._enterHandler = enterHandler;
        subMenuItem._leaveHandler = leaveHandler;
      });

      const removeHandlers = () => {
        subMenuItems.forEach(subMenuItem => {
          subMenuItem.removeEventListener('mouseenter', subMenuItem._enterHandler);
          subMenuItem.removeEventListener('mouseleave', subMenuItem._leaveHandler);

          delete subMenuItem._enterHandler;
          delete subMenuItem._leaveHandler;
        });

        mainItem.removeEventListener('mouseleave', removeHandlers);
        mainItem.removeEventListener('click', mainItemClickHandler);
      };

      mainItem.addEventListener('mouseleave', removeHandlers);
    });
  });
};
