<script>
import i18n from '@/$plugins/i18n'
import { unflatten } from '@/assets/js/helper/object'

export default {
  props: {
    translations: {
      type: Object,
      default: undefined
    },
    translationsOnce: {
      type: Boolean,
      default: undefined
    },
    translationsPath: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      tPath: this.tPath,
      translationsAllowLateInitialization: true
    }
  },
  beforeCreate () {
    const optionPropsData = this.$options.propsData ?? {}
    const translationsDefined = optionPropsData.translations !== undefined
    const translationsPath = (optionPropsData.translationsPath ?? this.$options.name ?? 'root').toLowerCase()
    this.tPath = translationsPath

    if (!translationsDefined) {
      return
    }

    const translationsPathDefined = optionPropsData.translationsPath !== undefined
    if (translationsPathDefined) {
      return
    }

    const translationsOnceDefined = optionPropsData.translationsOnce !== undefined
    const translationsAllreadySet = i18n.te(translationsPath)

    // set translations to i18n if
    // has translationsOnce not set to true or has translationsOnce set to true but tPath is not defined
    if (!translationsOnceDefined || (translationsOnceDefined && !translationsAllreadySet)) {
      const self = this
      if (this.translationsAllowLateInitialization) {
        this.$nextTick(() => {
          initializeTranslations.bind(self)(optionPropsData, translationsPath)
        })
      } else {
        initializeTranslations.bind(self)(optionPropsData, translationsPath)
      }
    }
  }
}

function initializeTranslations (optionPropsData, translationsPath) {
  const translationsData = optionPropsData.translations ?? {}

  // Convert keys to lowercase in-place
  for (const key in translationsData) {
    if (Object.prototype.hasOwnProperty.call(translationsData, key)) {
      const lowerKey = key.toLowerCase()
      if (lowerKey !== key) {
        translationsData[lowerKey] = translationsData[key]
        delete translationsData[key]
      }
    }
  }

  // Unflatten the translationsData object (assuming unflatten is an optimized function)
  const translations = unflatten(translationsData, '_')

  // Merge the translations into the Vue-i18n instance
  i18n.mergeLocaleMessage(i18n.locale, { [translationsPath]: translations })
}
</script>
