export default {
  date: {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  },
  dateDetailed: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long'
  }
}
