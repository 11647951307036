import vuelidate from './core'
import * as builtinValidators from 'vuelidate/lib/validators'
import customValidators from './hooks/custom-validators'

const validators = Object.assign({}, builtinValidators, customValidators)

export {
  vuelidate as default,
  validators
}
