import { LOCALE_STORAGE_KEYS } from '@/constants'

import Vue from 'vue'
import store from '@/$plugins/store/core'
import http from '@/$plugins/http'

import base from './base'
import IS from './base/is'

const LOCALE_STORAGE_KEYS_DEFINITION = {
  id: `${LOCALE_STORAGE_KEYS.shoppingcartid}`,
  count: `${LOCALE_STORAGE_KEYS.shoppingcartcount}`,
  itemIds: `${LOCALE_STORAGE_KEYS.shoppingcartitemids}`
}

export default {
  namespaced: true,
  state: base.createState(IS.state, {
    type: 'minimal',
    basket: {
      id: window.localStorage.getItem(LOCALE_STORAGE_KEYS_DEFINITION.id),
      itemCount: Number(window.localStorage.getItem(LOCALE_STORAGE_KEYS_DEFINITION.count) ?? 0),
      containedItemExternalIds: [],
      lastAction: null,
      setFromLocalStorage: false
    },
    localItemConfiguration: []
  }),
  getters: base.createGetters(IS.getters, {
    getType: state => state.type,
    get: state => state.basket,
    getId: state => state.basket.id,
    getItemCount: state => state.basket.itemCount,
    getItemIds: state => state.basket.containedItemExternalIds,
    getLastAction: state => state.basket.lastAction || {},
    getConfiguration: state => state.basket.configuration || {},
    getValidationResult: state => state.basket.configurationValidationResult || {},
    getStepValidationResult: state => sectionName => ((state.basket.configurationValidationResult || {}).results || []).find(r => r.sectionName === sectionName) || { isValid: false, errors: {} },
    getSummary: state => state.basket.priceDetail || {},
    getLocalItemConfiguration: state => productId => state.localItemConfiguration.find(c => c.productId === productId)
  }),
  mutations: base.createMutations(IS.mutations, {
    set (state, { basket, merge, omitLastAction }) {
      if (omitLastAction) delete basket.lastAction
      state.basket = merge ? Object.assign(state.basket, basket) : basket
      window.localStorage.setItem(LOCALE_STORAGE_KEYS_DEFINITION.id, state.basket.id)
      window.localStorage.setItem(LOCALE_STORAGE_KEYS_DEFINITION.count, state.basket.itemCount)
    },
    setLocalItemConfiguration (state, configuration = {}) {
      const updateIndex = state.localItemConfiguration.findIndex(c => c.productId === configuration.productId)
      const newIndex = state.localItemConfiguration.length
      const index = updateIndex >= 0 ? updateIndex : newIndex
      const base = state.localItemConfiguration[index] || {}

      Vue.set(state.localItemConfiguration, index, Object.assign(base, configuration))
    },
    setIdFromStorage (state, id = null) {
      if (id !== null && state.basket.id !== id) {
        state.basket.id = id
      }
    },
    setItemCountFromStorage (state, count = null) {
      const itemCount = Number(count)
      if (!isNaN(itemCount) && state.basket.itemCount !== itemCount) {
        state.basket.itemCount = itemCount
        state.basket.setFromLocalStorage = true
      }
    },
    resetItemCountSetFromStorage (state) {
      state.basket.setFromLocalStorage = false
    },
    setItemIdsFromStorage (state, itemIds = []) {
      const currentState = state.basket.containedItemExternalIds ?? null
      if (currentState === null || currentState.length !== itemIds.length) {
        state.basket.containedItemExternalIds = itemIds
      }
    }
  }),
  actions: base.createActions(IS.actions, {
    getMinimal ({ state, getters, commit, dispatch, rootGetters }) {
      const isKey = 'ShoppingCart/getMinimal'
      commit('setLoading', { key: isKey, loading: true, initial: state.basket.basketState === undefined })

      return new Promise((resolve, reject) => {
        http({
          method: 'get',
          url: `/order/api/ShoppingCart/small${getters.getId ? `/${getters.getId}` : ''}?lang=${rootGetters['gui/language:get']}`
        })
          .then(response => {
            if (response.data) {
              commit('set', { basket: response.data.result, merge: true })
              resolve(getters.get)
            }
          })
          .catch(reject)
          .finally(() => {
            commit('setLoading', { key: isKey, loading: false })
          })
      })
    },
    get ({ state, getters, commit, dispatch, rootGetters }) {
      const isKey = 'ShoppingCart/get'
      state.type = 'full'
      commit('setLoading', { key: isKey, loading: true, initial: state.basket.basketState === undefined })

      return new Promise((resolve, reject) => {
        http({
          method: 'get',
          url: `/order/api/ShoppingCart${getters.getId ? `/${getters.getId}` : ''}?lang=${rootGetters['gui/language:get']}`
        })
          .then(response => {
            localStorage.removeItem('cart-get-unauth-refresh')
            if (response.data) {
              commit('set', { basket: response.data.result, merge: false })
              resolve(getters.get)
            }
          })
          .catch(reason => {
            if (reason.response.status === 401 && !localStorage.getItem('cart-get-unauth-refresh')) {
              localStorage.setItem('cart-get-unauth-refresh', true)
              window.location.reload()
            }

            return reject(reason)
          })
          .finally(() => {
            commit('setLoading', { key: isKey, loading: false })
          })
      })
    },
    update ({ state, getters, commit, dispatch, rootGetters }, basketConfiguration = {}) {
      const isKey = 'ShoppingCart/update'
      commit('setSending', { key: isKey, sending: true })

      return new Promise((resolve, reject) => {
        http({
          method: 'put',
          url: `/order/api/ShoppingCart/${getters.getId}?lang=${rootGetters['gui/language:get']}`,
          data: basketConfiguration
        })
          .then(response => {
            localStorage.removeItem('cart-get-unauth-refresh')
            commit('set', { basket: response.data.result, merge: false })
            resolve(getters.get)
          })
          .catch(reason => {
            if (reason.response.status === 401 && !localStorage.getItem('cart-get-unauth-refresh')) {
              localStorage.setItem('cart-get-unauth-refresh', true)
              window.location.reload()
            }

            return reject(reason)
          })
          .finally(() => {
            commit('setSending', { key: isKey, sending: false })
          })
      })
    },
    delete ({ state, getters, commit, dispatch, rootGetters }) {
      const isKey = 'ShoppingCart/delete'
      commit('setSending', { key: isKey, sending: true })

      return new Promise((resolve, reject) => {
        http({
          method: 'delete',
          url: `/order/api/ShoppingCart/${getters.getId}?lang=${rootGetters['gui/language:get']}`
        })
          .then(response => {
            commit('set', { basket: response.data.result, merge: false })
            resolve(getters.get)
          })
          .catch(reject)
          .finally(() => {
            commit('setSending', { key: isKey, sending: false })
          })
      })
    },
    addItem ({ state, getters, commit, dispatch, rootGetters }, { productId = '', quantity = 0, configuration = {}, gtmList = 'Warenkorb', omitLastAction = false, reloadFullBasket = false }) {
      const isKey = 'ShoppingCart/addItem'
      commit('setLoading', { key: isKey, loading: true, initial: state.basket.basketState === undefined })

      const localItemConfiguration = state.localItemConfiguration.find(c => c.productId === productId) ?? {}
      const itemConfiguration = Object.assign(localItemConfiguration, configuration)

      return new Promise((resolve, reject) => {
        http({
          method: 'put',
          url: `/order/api/ShoppingCart/${getters.getId}/items/add/${quantity}/${productId}?lang=${rootGetters['gui/language:get']}`,
          data: itemConfiguration
        })
          .then(response => {
            dispatch('gtm/addToCart', {
              list: gtmList,
              product: Object.assign({}, response.data.result.lastAction.product, { quantity: response.data.result.lastAction.quantityChange })
            }, { root: true })

            commit('set', { basket: response.data.result, merge: true, omitLastAction })

            if (reloadFullBasket) {
              dispatch('get')
            }
            resolve(getters.get)
          })
          .catch(reject)
          .finally(() => {
            commit('setLoading', { key: isKey, loading: false })
          })
      })
    },
    updateItem ({ state, getters, commit, dispatch, rootGetters }, { item = {}, omitLastAction = false }) {
      const isKey = 'ShoppingCart/updateItem'
      commit('setSending', { key: isKey, sending: true })

      const basketItem = state.basket.items.find(i => i.basketItemId === item.id) || {}
      const itemConfiguration = basketItem.configuration || {}
      const quantityChange = item.quantity - basketItem.quantity
      const gtmEventName = quantityChange >= 0 ? 'addToCart' : 'removeFromCart'

      return new Promise((resolve, reject) => {
        http({
          method: 'put',
          url: `/order/api/ShoppingCart/${getters.getId}/items/${item.id}/update/${item.quantity}?lang=${rootGetters['gui/language:get']}`,
          data: itemConfiguration
        })
          .then(response => {
            if (response.data) {
              dispatch(`gtm/${gtmEventName}`, {
                list: 'Warenkorb',
                product: Object.assign({}, response.data.result.lastAction.product, { quantity: quantityChange })
              }, { root: true })
              commit('set', { basket: response.data.result, merge: false, omitLastAction })
              resolve(getters.get)
            }
          })
          .catch(reject)
          .finally(() => {
            commit('setSending', { key: isKey, sending: false })
          })
      })
    },
    deleteItem ({ state, getters, commit, dispatch, rootGetters }, { item = {}, omitLastAction = false }) {
      const isKey = 'ShoppingCart/addItem'
      commit('setSending', { key: isKey, sending: true })

      // const itemConfiguration = (state.basket.items.find(i => i.basketItemId === item.id) || {}).configuration || {}

      return new Promise((resolve, reject) => {
        http({
          method: 'delete',
          url: `/order/api/ShoppingCart/${getters.getId}/items/${item.id}/delete?lang=${rootGetters['gui/language:get']}`
        })
          .then(response => {
            dispatch('gtm/removeFromCart', {
              list: 'Warenkorb',
              product: Object.assign({}, response.data.result.lastAction.product, { quantity: response.data.result.lastAction.quantityChange })
            }, { root: true })
            commit('set', { basket: response.data.result, merge: false, omitLastAction })
            resolve()
          })
          .catch(reject)
          .finally(() => {
            commit('setSending', { key: isKey, sending: false })
          })
      })
    }
  })
}

window.addEventListener('storage', e => {
  if (Object.values(LOCALE_STORAGE_KEYS_DEFINITION).includes(e.key)) {
    if (e.key === LOCALE_STORAGE_KEYS_DEFINITION.id) store.commit('shoppingcart/setIdFromStorage', e.newValue)
    if (e.key === LOCALE_STORAGE_KEYS_DEFINITION.count) store.commit('shoppingcart/setItemCountFromStorage', e.newValue)
    if (e.key === LOCALE_STORAGE_KEYS_DEFINITION.itemIds) store.commit('shoppingcart/setItemIdsFromStorage', e.newValue)
  }
})
