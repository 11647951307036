import http from '@/$plugins/http'

import base from './base'
import IS from './base/is'

export default {
  namespaced: true,
  state: base.createState(IS.state, {
    order: {}
  }),
  getters: base.createGetters(IS.getters, {
    get: state => state.order,
    getBasket: state => state.order.basket || {},
    getConfiguration: state => state.order.configuration || {},
    getValidation: state => state.order.orderValidation || {}
  }),
  mutations: base.createMutations(IS.mutations, {
    set (state, order) {
      state.order = order
    }
  }),
  actions: base.createActions(IS.actions, {
    initialize ({ state, getters, commit, dispatch, rootGetters }) {
      const isKey = 'Checkout/initialize'
      commit('setLoading', { key: isKey, loading: true, initial: state.order.orderId === undefined })

      return new Promise((resolve, reject) => {
        http({
          method: 'put',
          url: `/order/api/Checkout/initialize/${rootGetters['shoppingcart/getId']}?lang=${rootGetters['gui/language:get']}`
        })
          .then(response => {
            if (response.data) {
              commit('set', response.data.result)
              resolve(getters.get)
            }
          })
          .catch(reject)
          .finally(() => {
            commit('setLoading', { key: isKey, loading: false })
          })
      })
    },
    get ({ state, getters, commit, dispatch, rootGetters }) {
      const isKey = 'Checkout/get'
      commit('setLoading', { key: isKey, loading: true, initial: state.order.orderId === undefined })

      return new Promise((resolve, reject) => {
        http({
          method: 'get',
          url: `/order/api/Checkout/order/forbasket/${rootGetters['shoppingcart/getId']}?lang=${rootGetters['gui/language:get']}`
        })
          .then(response => {
            if (response.data) {
              commit('set', response.data.result)
              resolve(getters.get)
            }
          })
          .catch(reject)
          .finally(() => {
            commit('setLoading', { key: isKey, loading: false })
          })
      })
    },
    execute ({ state, getters, commit, dispatch, rootGetters }, orderConfiguration = { orderType: 'Order' }) {
      const isKey = 'Checkout/execute'
      commit('setLoading', { key: isKey, sending: true })

      return new Promise((resolve, reject) => {
        http({
          method: 'put',
          url: `/order/api/Checkout/execute/${rootGetters['shoppingcart/getId']}?lang=${rootGetters['gui/language:get']}`,
          data: orderConfiguration
        })
          .then(response => {
            commit('set', response.data.result)
            resolve(getters.get)
          })
          .catch(reject)
          .finally(() => {
            commit('setLoading', { key: isKey, sending: false })
          })
      })
    }
  })
}
