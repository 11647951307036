import {
  DEFAULT_FILEPOND_URLS,
  CHANGEREQUEST_STATES_MAP,
  COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP,
  COMPONENT_CONTROL_DISCRIMINATOR_CONTROL_MAP,
  COMPONENT_CONTROL_DISCRIMINIATOR_CHECK_MAP,
  COMPONENT_CONTROL_ENTITYPROPERTY_HIDE_BY_PROPERTIES,
  COMPONENT_CONTROL_ENTITYPROPERTY_ROWGROUPS
} from '@/constants'

import Vue from 'vue'
import store from '@/$plugins/store/core'
import isValid from 'date-fns/esm/isValid'
import format from 'date-fns/esm/format'
import { guid } from '@/assets/js/helper/guid'
import string from '@/assets/js/helper/string'

const globalTranslations = window.globalTranslations || {}

export function normalize(entity = {}, key = null) {
  const isWrapped = Object.prototype.hasOwnProperty.call(entity, 'value') && Object.prototype.hasOwnProperty.call(entity, 'changeRequests')
  const lowerKey = key ? key.toLowerCase() : key

  return isWrapped ? Object.assign({}, entity, { key: lowerKey }) : { key: lowerKey, value: entity, changeRequests: [] }
}

export function unwrap(entity = {}) {
  const isWrapped = Object.prototype.hasOwnProperty.call(entity, 'value') && Object.prototype.hasOwnProperty.call(entity, 'changeRequests')
  return isWrapped ? entity.value : entity
}

export function entityLabelFormatter(definition = {}, entity = {}) {
  const LABEL_FORMAT = definition.labelFormat || ''
  const ENTITY = unwrap(entity)

  return LABEL_FORMAT.replace(/{(.+?)(?::(.+))?}/gi, (match, $1 = '', $2 = '') => {
    const propertyName = string.decapitalize($1)
    const propertyValue = ENTITY[propertyName]
    const propertyFormat = $2
    const isDateValue = isValid(new Date(propertyValue))

    if (isDateValue && propertyFormat) {
      return format(new Date(propertyValue), propertyFormat)
    } else if (propertyValue && propertyFormat) {
      // TODO: define formatting function
      return propertyValue
    }

    return propertyValue || match
  })
}

export function propertyLabelExtender(label = '', validators = []) {
  if (label.length <= 1) {
    return label
  }

  if (validators.includes('requiredValidator')) {
    return `${label} *`
  }

  return label
}

export function labelFormatter(definition = {}, propertyKey = '') {
  return (((definition.properties.find((p) => p.name === propertyKey) || {}).translations || {})[store.getters['gui/language:get']] || {}).name || ''
}

export function valueFormatter(definition = {}, propertyKey = '', propertyValue) {
  const PROPERTY = definition.properties.find((p) => p.name === propertyKey) || {}
  const TYPE_DISCRIMINATOR = PROPERTY.attributeTypeDiscriminator || null
  const CONTROL = Object.values(COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP).find((mVal) => mVal.type === TYPE_DISCRIMINATOR) || {}
  const SETTINGS = PROPERTY.settings || {}
  const CONTROL_SETTINGS = Object.assign({}, SETTINGS, CONTROL)

  const VALUE = propertyValue || CONTROL.baseValue
  const TRANSLATIONS = (PROPERTY.translations || {})[store.getters['gui/language:get']] || {}
  const SELECT_VALUES = TRANSLATIONS.selectHtmlLabels || TRANSLATIONS.selectValues || null

  const formattedValue = {
    value: propertyValue,
    html: propertyValue || ''
  }

  if (Array.isArray(VALUE)) {
    if (CONTROL.component === 'control-files') {
      const FILEPOND_DOWNLOAD_URL = `${store.getters['configuration/getBaseUrl']}${CONTROL_SETTINGS.downloadUrl || DEFAULT_FILEPOND_URLS.download}`

      formattedValue.value = VALUE.join(', ')
      formattedValue.html = VALUE.map(
        (fileId, fIndex) =>
          `<a href="${FILEPOND_DOWNLOAD_URL}${fileId}" download>${(globalTranslations.components_links_label_DownloadFile || fileId).replace(
            '{count}',
            fIndex + 1
          )}</a>`
      ).join('<br/>')
    } else if (SELECT_VALUES) {
      const isHtmlLabels = TRANSLATIONS.selectHtmlLabels !== undefined
      const mappedValues = Object.keys(SELECT_VALUES)
        .filter((vKey) => VALUE.includes(vKey))
        .map((vKey) => SELECT_VALUES[vKey])
      const badgeClasses = ['badge', isHtmlLabels ? 'badge-light' : 'badge-dark', isHtmlLabels ? 'badge-lg' : ''].join(' ').trim()

      formattedValue.value = mappedValues
      formattedValue.html = `<div class="badge-group">${mappedValues.map((v) => `<span class="${badgeClasses}">${v}</span>`).join(' ')}</div>`
    }
  } else {
    if (SELECT_VALUES) {
      const mappedValue = SELECT_VALUES[propertyValue]

      formattedValue.value = mappedValue
      formattedValue.html = mappedValue || ''
    }
  }

  return formattedValue
}

export function propertyMapper(
  definition = {},
  language = '',
  validators = [],
  entity = {},
  allowedChangeRequestStates = Object.values(CHANGEREQUEST_STATES_MAP)
) {
  const PROPERTIES = definition.properties || []
  const ENTITY = unwrap(entity)
  const TEMP_VALUES = JSON.parse(JSON.stringify(ENTITY))
  const MODE = !ENTITY.id ? 'create' : 'edit'
  const CHANGE_REQUESTS = getAllowedChangeRequests()

  let previousGroupKey = null

  const mappedProperties = PROPERTIES.map((p, i) => {
    const CONTROL = COMPONENT_CONTROL_DISCRIMINATOR_CONTROL_MAP[p.attributeTypeDiscriminator] || COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP.unknown
    const SETTINGS = p.settings || {}
    const CONTROL_SETTINGS = Object.assign({}, SETTINGS, CONTROL)
    const GROUP_KEY = SETTINGS.group || null
    const ENTITY_VALUE = JSON.parse(
      JSON.stringify(
        (ENTITY[p.name] || SETTINGS.defaultValue || CONTROL.baseValue) !== undefined
          ? ENTITY[p.name] || SETTINGS.defaultValue || CONTROL.baseValue
          : null
      )
    )
    const ENTITY_VALUE_BASE = JSON.parse(
      JSON.stringify((SETTINGS.defaultValue || CONTROL.baseValue) !== undefined ? SETTINGS.defaultValue || CONTROL.baseValue : null)
    )
    const TRANSLATIONS = p.translations[language]
    const VISIBILITY_CHECKS = SETTINGS.visibility || []

    let hiddenByValue = false

    const _table = GROUP_KEY && GROUP_KEY !== previousGroupKey ? { title: TRANSLATIONS.group } : null
    const _grid = getGridOptions()
    const options = getOptions()
    const configuration = getConfiguration()
    const validations = getValidations()
    const value = getValue()
    const readonly = getReadonly()
    const hidden = getHidden()

    const filepondConfig = getFilepondConfig()

    previousGroupKey = GROUP_KEY

    const property = {
      _table,
      _grid,
      name: p.name,
      label: entityLabelFormatter(definition, entity),
      control: Object.assign(
        {
          is: CONTROL.component,
          name: p.name,
          id: `${p.name}-${guid()}`,
          type: CONTROL_SETTINGS.controlType,
          label: propertyLabelExtender(TRANSLATIONS.name, SETTINGS.validators),
          labelSrOnly: CONTROL_SETTINGS.labelSrOnly,
          labelCols: CONTROL_SETTINGS.labelCols || null,
          text: TRANSLATIONS.text || TRANSLATIONS.name,
          description: store.getters['configuration/get'].isOnline ? TRANSLATIONS.tooltip : null,
          value,
          resetValue: value || ENTITY_VALUE_BASE,
          options,
          configuration,
          dirty: null,
          size: CONTROL_SETTINGS.size,
          disabled: CONTROL_SETTINGS.disabled,
          readonly,
          plaintext: CONTROL_SETTINGS.plaintext,
          hidden,
          autocomplete: CONTROL_SETTINGS.autocomplete,
          pattern: CONTROL_SETTINGS.pattern,
          validations: validations.validators,
          feedbacksInvalid: validations.feedbacksInvalid,
          validatorDefinitions: validations.validatorDefinitions,
          editMode: false,
          hardChange: CONTROL_SETTINGS.requiresHardChange ? hardChange : null
        },
        filepondConfig || {}
      ),
      changeRequests: CHANGE_REQUESTS[p.name] || [],
      visible: null,
      computeVisibility,
      change,
      update,
      touch,
      reset
    }

    updateComputed()

    return property

    function getGridOptions() {
      let cols = 12
      const GRID_ROWGROUP_MAPS = COMPONENT_CONTROL_ENTITYPROPERTY_ROWGROUPS.filter((m) => Object.keys(m).includes(p.name))

      GRID_ROWGROUP_MAPS.forEach((map) => {
        const hasEntityMatch = map.entityName === undefined || map.entityName === definition.name
        const gridRowgroupMapKeys = Object.keys(map).filter((k) => k !== 'entityName')
        const propertyIndex = PROPERTIES.findIndex((_p) => _p.name === p.name)
        const propertyMapIndex = gridRowgroupMapKeys.indexOf(p.name)
        const propertyCheckStartIndex = propertyIndex - propertyMapIndex
        const propertyCheckEndIndex = propertyCheckStartIndex + gridRowgroupMapKeys.length
        const propertyOrderCheck =
          PROPERTIES.slice(propertyCheckStartIndex, propertyCheckEndIndex)
            .map((_p) => _p.name)
            .join('-') === gridRowgroupMapKeys.join('-')

        if (hasEntityMatch && propertyOrderCheck) cols = map[p.name]
      })

      return { cols }
    }

    function getOptions() {
      if (SETTINGS.selectValues) {
        return SETTINGS.selectValues.map((v) => ({ value: v, text: TRANSLATIONS.selectValues[v] }))
      } else {
        return null
      }
    }

    function getConfiguration() {
      return Object.assign(
        {
          entityId: definition.id,
          propertyName: p.name
        },
        SETTINGS
      )
    }

    function getValidations() {
      return [].concat(SETTINGS.validators || [], CONTROL.validators || [], SETTINGS.customValidators || [], CONTROL.customValidators || []).reduce(
        (validations, validator) => {
          const isCustomValidators = typeof validator === 'object'
          const validatorDefinition = isCustomValidators ? validator : validators.find((v) => v.id === validator) || null

          if (isCustomValidators) {
            Object.keys(validatorDefinition).forEach((vKey) => {
              const baseDefinition = validatorDefinition[vKey] || {}
              const vDefinition = {
                id: vKey,
                type: `${vKey}Validator`,
                typeDiscriminator: 'customValidator',
                isFallbackValidator: false,
                settings: {},
                translations: {
                  [language]: {
                    errorMessage: (TRANSLATIONS.feedbacksInvalid || {})[vKey] || ''
                  }
                },
                validation: typeof baseDefinition.validation === 'function' ? baseDefinition.validation : () => true,
                parameters: [].concat(baseDefinition.parameters || [])
              }

              validations.validators = Object.assign(validations.validators, {
                [vKey]: vDefinition.parameters.length > 0 ? vDefinition.validation(...vDefinition.parameters) : vDefinition.validation
              })
              validations.feedbacksInvalid = Object.assign(validations.feedbacksInvalid, { [vKey]: vDefinition.translations[language].errorMessage })
              validations.validatorDefinitions = Object.assign(validations.validatorDefinitions, { [vKey]: vDefinition })
            })
          } else if (validatorDefinition !== null) {
            validations.validators = Object.assign(validations.validators, { [validatorDefinition.type]: validatorDefinition.validation })
            validations.feedbacksInvalid = Object.assign(validations.feedbacksInvalid, {
              [validatorDefinition.type]: validatorDefinition.translations[language].errorMessage
            })
            validations.validatorDefinitions = Object.assign(validations.validatorDefinitions, { [validatorDefinition.type]: validatorDefinition })
          }

          return validations
        },
        { validators: {}, feedbacksInvalid: {}, validatorDefinitions: {} }
      )
    }

    function getValue() {
      if (SETTINGS.virtual && SETTINGS.virtualPropertySettings && SETTINGS.virtualPropertySettings.valueFormat) {
        return string.format(SETTINGS.virtualPropertySettings.valueFormat, ENTITY)
      }

      if (MODE === 'create') {
        const hasOneOption = options !== null && options.length === 1
        const entityValueIsInvalid = !Object.values(validations.validators).every((v) => v(ENTITY_VALUE))

        if (hasOneOption && entityValueIsInvalid) {
          hiddenByValue = true
          return options[0].value
        }
      }

      return ENTITY_VALUE
    }

    function getReadonly() {
      if (MODE === 'edit') {
        return CONTROL_SETTINGS.readOnly || CONTROL_SETTINGS.createOnly
      }

      return CONTROL_SETTINGS.readOnly
    }

    function getHidden() {
      return hiddenByValue || (MODE === 'create' && CONTROL_SETTINGS.readOnly) || CONTROL_SETTINGS.hidden
    }

    function getFilepondConfig() {
      if (CONTROL.component === 'control-files') {
        return {
          serverConfig: {
            uploadUrl: CONTROL_SETTINGS.uploadUrl,
            downloadUrl: CONTROL_SETTINGS.downloadUrl,
            deleteUrl: CONTROL_SETTINGS.deleteUrl
          },
          allowRemove: MODE === 'create',
          allowFileTypeValidation: Array.isArray(CONTROL_SETTINGS.allowedFileTypes) && CONTROL_SETTINGS.allowedFileTypes.length > 1,
          acceptedFileTypes: CONTROL_SETTINGS.allowedFileTypes,
          maxFileSize: CONTROL_SETTINGS.maximumSize
        }
      }

      return null
    }

    function computeVisibility() {
      const CONTROL_SETTING_KEYS = Object.keys(CONTROL_SETTINGS)
      const BASE_VISIBILITY = !COMPONENT_CONTROL_ENTITYPROPERTY_HIDE_BY_PROPERTIES.reduce(
        (hide, pKey) => (CONTROL_SETTING_KEYS.includes(pKey) ? CONTROL_SETTINGS[pKey] : hide),
        false
      )

      return (
        BASE_VISIBILITY &&
        (VISIBILITY_CHECKS.length <= 0 ||
          VISIBILITY_CHECKS.some((c) => {
            const check =
              (COMPONENT_CONTROL_DISCRIMINIATOR_CHECK_MAP[c.checkTypeDiscriminator] || {}).check ||
              function () {
                return true
              }
            const checkValue = TEMP_VALUES[c.propertyName]

            return check(c.propertyValue, checkValue)
          }))
      )
    }

    function hardChange(value) {
      property.control.value = value
    }

    function change() {
      TEMP_VALUES[property.name] = property.control.value
      property.control.dirty = true
      updateComputed()
      computeAllVisibilities()
    }

    function update() {
      TEMP_VALUES[property.name] = property.control.value
      property.control.resetValue = property.control.value
      property.control.dirty = true
      updateComputed()
      computeAllVisibilities()
    }

    function touch() {
      update()
    }

    function reset(skipComputeAllVisibilities = false) {
      TEMP_VALUES[property.name] = property.control.resetValue
      property.control.value = property.control.resetValue
      property.control.dirty = false
      updateComputed()
      if (!skipComputeAllVisibilities) computeAllVisibilities()
    }

    function updateComputed() {
      property.visible = computeVisibility()
    }
  })

  function getAllowedChangeRequests() {
    return (entity.changeRequests || [])
      .filter((cr) => allowedChangeRequestStates.includes(cr.status))
      .reduce((changeRequests, cr) => Object.assign(changeRequests, { [cr.propertyName]: (changeRequests[cr.propertyName] || []).concat(cr) }), {})
  }

  function computeAllVisibilities() {
    mappedProperties.forEach((p) => {
      p.visible = p.computeVisibility()
      if (!p.visible) p.reset(true)
    })
  }

  return Vue.observable(mappedProperties)
}

export function controlMapper(
  definition = {},
  language = '',
  validators = [],
  entity = {},
  allowedChangeRequestStates = Object.values(CHANGEREQUEST_STATES_MAP)
) {
  return propertyMapper(definition, language, validators, entity, allowedChangeRequestStates).reduce(
    (controls, property) => Object.assign(controls, { [property.name]: property }),
    {}
  )
}

export function preProcessEntityProperties(entityProperties) {
  const result = []

  entityProperties.forEach((property) => {
    const isVirtual = property.control.configuration?.virtual
    const haveMappings = property.control.configuration.virtualPropertySettings?.mappings

    if (isVirtual && haveMappings) {
      if (property.value !== property.control.resetValue) {
        Object.keys(property.control.configuration.virtualPropertySettings.mappings).forEach((sourceProperty) => {
          const targetProperty = property.control.configuration.virtualPropertySettings.mappings[sourceProperty]
          const sourceValue = property.value ? property.value[sourceProperty] : ''

          entityProperties.find((p) => p.name === targetProperty).control.value = sourceValue
        })
      }
    } else {
      result.push(property)
    }
  })

  return result
}

export function preProcessControls(controls, controlData) {
  const result = {}

  Object.keys(controls).forEach((key) => {
    const isVirtual = controls[key].control.configuration?.virtual
    const haveMappings = controls[key].control.configuration.virtualPropertySettings?.mappings

    if (isVirtual && haveMappings) {
      if (controls[key].control.value !== controls[key].control.resetValue && controlData[key] !== undefined) {
        Object.keys(controls[key].control.configuration.virtualPropertySettings.mappings).forEach((sourceProperty) => {
          const targetProperty = controls[key].control.configuration.virtualPropertySettings.mappings[sourceProperty]
          const sourceValue = controlData[key] ? controlData[key][sourceProperty] : ''

          result[targetProperty] = sourceValue
        })
      }
    } else if (result[key] === undefined) {
      result[key] = controlData[key]
    }
  })

  return result
}

export default {
  normalize,
  unwrap,
  entityLabelFormatter,
  propertyLabelExtender,
  labelFormatter,
  valueFormatter,
  propertyMapper,
  controlMapper,
  preProcessEntityProperties,
  preProcessControls
}
