import { INITIAL_LANGUAGE, AVAILABLE_LANGUAGES, DATETIME_LOCALES, getCountryCodeByIsoLanguage, setLanguage } from '@/$plugins/i18n/config'
import i18n from '@/$plugins/i18n/core'

const CONFIGURATION = window.guiConfiguration ?? {}

export default {
  namespaced: true,
  state: {
    language: {
      available: AVAILABLE_LANGUAGES,
      active: INITIAL_LANGUAGE
    },
    number: {
      decimalSeparator: i18n.n(1.1, 'decimal').replace(/[0-9]+/g, '').trim(),
      thousandSeparator: i18n.n(1000, 'decimal').replace(/[0-9]+/g, '').trim()
    },
    datetime: {
      isoCode: DATETIME_LOCALES[INITIAL_LANGUAGE].code,
      weekStartsOn: DATETIME_LOCALES[INITIAL_LANGUAGE].options.weekStartsOn
    },
    urls: {
      product: CONFIGURATION.productBaseUrl ?? '',
      productvariant: CONFIGURATION.productVariantBaseUrl ?? ''
    }
  },
  getters: {
    'language:get': state => state.language.active,
    'language:country': state => getCountryCodeByIsoLanguage(state.language.active),
    'language:available': state => state.language.available,

    'number:get': state => state.number,
    'number:getDecimalSeparator': state => state.number.decimalSeparator,
    'number:getThousandSeparator': state => state.number.thousandSeparator,

    'datetime:get': state => state.datetime,
    'datetime:getIsoCode': state => state.datetime.isoCode,
    'datetime:getWeekStartsOn': state => state.datetime.weekStartsOn,
    'datetime:getIsoCodeOptions': state => language => DATETIME_LOCALES[language],

    'urls:get': state => state.urls,
    'urls:getType': state => (type = '', slug = '') => `${state.urls[type] ?? ''}/${slug}`.replace(/\/\//g, '/')
  },
  mutations: {
    'language:set' (state, language = '') {
      state.language.active = setLanguage(language, state.language.active)
      state.datetime.isoCode = DATETIME_LOCALES[state.language.active].code
      state.datetime.weekStartsOn = DATETIME_LOCALES[state.language.active].options.weekStartsOn
    }
  },
  actions: {}
}
