import { instances } from '../core'
import configuration from '@/$plugins/store/modules/configuration'

Object.values(instances)
  .forEach(i => {
    i.interceptors.request.use(
      config => {
        config.headers.Authorization = `Bearer ${configuration.getters.getToken(configuration.state)}`
        return config
      },
      null
    )
  })
