import { PAGINATION_INITIAL_VALUES } from '@/constants'

import Vue from 'vue'
import http from '@/$plugins/http'

import base from './base'
import IS from './base/is'

export default {
  namespaced: true,
  state: base.createState(IS.state, {
    statistics: {
      page: {
        number: PAGINATION_INITIAL_VALUES.page,
        size: PAGINATION_INITIAL_VALUES.pageSize
      },
      total: {
        items: 0,
        pages: 0
      }
    },
    orders: []
  }),
  getters: base.createGetters(IS.getters, {
    statistics: state => state.statistics,
    get: state => state.orders,
    getOrder: state => id => state.orders.find(o => o.orderId === id)
  }),
  mutations: base.createMutations(IS.mutations, {
    setStatistics (state, statistics = {}) {
      state.statistics.page.number = statistics.pageNumber
      state.statistics.page.size = statistics.pageSize
      state.statistics.total.items = statistics.totalItems
      state.statistics.total.pages = statistics.totalPages
    },
    set (state, { orders }) {
      state.orders = orders
    },
    setOrder (state, order = {}) {
      const updateIndex = state.orders.findIndex(o => o.orderId === order.orderId)
      const newIndex = state.orders.length

      Vue.set(state.orders, updateIndex >= 0 ? updateIndex : newIndex, order)
    }
  }),
  actions: base.createActions(IS.actions, {
    getAll ({ state, getters, commit, dispatch, rootGetters }, options = {}) {
      const isKey = 'OrderAccessor/getAll'
      const o = base.getOptions(options, {
        lang: rootGetters['gui/language:get']
      })

      commit('setLoading', { key: isKey, loading: true, initial: true })

      return new Promise((resolve, reject) => {
        http({
          method: 'get',
          url: `/order/api/OrderAccessor${o.by.url.from}/all`,
          params: o.data
        })
          .then(response => {
            commit('set', { orders: response.data.result })
            resolve(getters.get)
          })
          .catch(reject)
          .finally(() => {
            commit('setLoading', { key: isKey, loading: false })
          })
      })
    },
    get ({ state, getters, commit, dispatch, rootGetters }, options = {}) {
      const isKey = 'OrderAccessor/get'

      const o = base.getOptions(options, {
        lang: rootGetters['gui/language:get'],
        page: state.statistics.page.number,
        pageSize: state.statistics.page.size
      })

      commit('setLoading', { key: isKey, loading: true, initial: state.orders.length === 0 })

      return new Promise((resolve, reject) => {
        http({
          method: 'get',
          url: `/order/api/OrderAccessor${o.by.url.from}`,
          params: o.data
        })
          .then(response => {
            commit('set', { orders: response.data.result })
            resolve(getters.get)
          })
          .catch(reject)
          .finally(() => {
            commit('setLoading', { key: isKey, loading: false })
          })
      })
    },
    getOrder ({ state, getters, commit, dispatch, rootGetters }, orderId = null) {
      const isKey = 'OrderAccessor/getOrder'

      commit('setLoading', { key: isKey, loading: true, initial: state.orders.length === 0 })

      return new Promise((resolve, reject) => {
        http({
          method: 'get',
          url: `/order/api/OrderAccessor/${orderId}`,
          params: {
            lang: rootGetters['gui/language:get']
          }
        })
          .then(response => {
            commit('setOrder', response.data.result)
            resolve(getters.getOrder(orderId))
          })
          .catch(reject)
          .finally(() => {
            commit('setLoading', { key: isKey, loading: false })
          })
      })
    }
  })
}
