import { cloneContainerAndPrepareSildeInFromLeft, cloneContainerAndPrepareSildeInFromRight, setActiveMobileLevel, slideInFromLeftAndCleanup, slideInFromRightAndCleanup } from './utils.js';

// Initialize mobile navigation menu
export const initializeMobileNav = (navigation, mainNavigation, attachedEventHandlers) => {
  const forwardCaretIcon = document.getElementById('ForwardCaretIcon');
  const backToMainLink = navigation.querySelector('.back-to-mainnav')
  const initialActiveItems = Array.from(navigation.querySelectorAll('.active')).reverse();
  let activeLevelSet = false;

  initialActiveItems.forEach(lastItem => {
    if (lastItem.closest('.metanavigation')) {
      return;
    }
    if (activeLevelSet || lastItem.classList.contains('ignore-mobile')) {
      return;
    }

    const closestActiveRightContent = lastItem.closest('.right-content');
    if (closestActiveRightContent) {
      lastItem = closestActiveRightContent.closest('.menu-item').children[0];
    }

    if (!lastItem.tagName || lastItem.tagName.toLowerCase() !== 'a') {
      lastItem = lastItem.children[0];
    }

    setActiveMobileLevel(lastItem, navigation, mainNavigation);
    activeLevelSet = true;
  });

  if (!activeLevelSet) {
    setActiveMobileLevel(mainNavigation, navigation, mainNavigation);
  }

  navigation.querySelectorAll('.main-item .main-item-link, .menu-item > .item-link').forEach(item => {
    const parent = item.parentElement;
    const hasChildren = parent.querySelector('.mega-dropdown, .right-content');

    const clickHandler = (e) => {
      if (item.classList.contains('mobile-active-link') || parent.querySelector('.mega-dropdown > .mobile-active')) {
        return;
      }

      if (parent === backToMainLink) {
        e.preventDefault();
        e.stopPropagation();

        const menuContainer = navigation;
        const menuContainerClone = cloneContainerAndPrepareSildeInFromLeft(menuContainer)
        setActiveMobileLevel(mainNavigation, navigation, mainNavigation);
        slideInFromLeftAndCleanup(menuContainer, menuContainerClone);
      }

      if (hasChildren) {
        e.preventDefault();
        e.stopPropagation();

        const isBack = parent.classList.contains('mobile-active-path');
        const menuContainer = navigation;
        const menuContainerClone = isBack ? cloneContainerAndPrepareSildeInFromLeft(menuContainer) : cloneContainerAndPrepareSildeInFromRight(menuContainer);
        setActiveMobileLevel(item, navigation, mainNavigation);

        if (isBack) {
          slideInFromLeftAndCleanup(menuContainer, menuContainerClone);
        } else {
          slideInFromRightAndCleanup(menuContainer, menuContainerClone);
        }
      }
    };

    if (hasChildren) {
      const forwardCaret = forwardCaretIcon?.cloneNode(true);
      if (forwardCaret) {
        forwardCaret.classList.remove('d-none');
        forwardCaret.classList.add('forward-caret-icon');

        item.appendChild(forwardCaret);
      }
    }

    item.addEventListener('click', clickHandler);
    attachedEventHandlers.push({ element: item, event: 'click', handler: clickHandler });
  });
};

// Destroy mobile navigation menu
export const destroyMobileNav = (navigation, attachedEventHandlers) => {
  navigation.querySelectorAll('.mobile-active, .mobile-active-link, .mobile-active-path').forEach(activeItem => {
    activeItem.classList.remove('mobile-active', 'mobile-active-link', 'mobile-active-path');
  });

  navigation.querySelectorAll('.back-caret-icon').forEach(backCaret => {
    backCaret.remove();
  });

  navigation.querySelectorAll('.forward-caret-icon').forEach(forwardCaret => {
    forwardCaret.remove();
  });

  attachedEventHandlers.forEach(({ element, event, handler }) => {
    element.removeEventListener(event, handler);
  });

  attachedEventHandlers.length = 0;
};
