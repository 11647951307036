import { initializeDesktopNav } from './desktop-nav.js';
import { destroyMobileNav, initializeMobileNav } from './mobile-nav.js';
import { getHeaderInViewportHeight } from './utils.js';

document.addEventListener('DOMContentLoaded', () => {
  const navigation = document.querySelector('.menu-container');
  const mainNavigation = navigation.querySelector('.main-items');
  const attachedEventHandlers = [];

  const mobileNavButton = document.querySelector('.header-menutoggle');
  const mobileNavContainer = document.querySelector('.header-menu .menu-container');
  let resetTopTimeout = null;

  // Handle mobile navigation button click
  mobileNavButton.addEventListener('click', () => {
    if (resetTopTimeout) {
      clearTimeout(resetTopTimeout);
    }

    const openNavigation = !mobileNavButton.classList.contains('is-visible');
    if (openNavigation) {
      mobileNavContainer.style.top = `${getHeaderInViewportHeight()}px`;
      mobileNavButton.classList.add('is-visible');
      document.body.classList.add('mobile-nav-open');
      initializeMobileNav(navigation, mainNavigation, attachedEventHandlers);
    } else {
      mobileNavButton.classList.remove('is-visible');
      document.body.classList.remove('mobile-nav-open');

      resetTopTimeout = setTimeout(() => {
        mobileNavContainer.style.removeProperty('top');
      }, 250);

      destroyMobileNav(navigation, attachedEventHandlers);
    }

    window.vueInstance.$root.$emit('scrollbar:toggle', { key: 'HeaderMenu', state: openNavigation, from: 0, till: window.vueInstance.$root.md.value });
  });

  // Initialize desktop navigation
  initializeDesktopNav();
});
