const DEV_COMPONENTS = {}
const COMPONENTS = {}

if (process.env.NODE_ENV === 'development') {
  DEV_COMPONENTS.DevVars = () => import(/* webpackChunkName: "dev" */ '@/components/_dev/RootVariables')
  DEV_COMPONENTS.DevColors = () => import(/* webpackChunkName: "dev" */ '@/components/_dev/Colors')
  DEV_COMPONENTS.DevShadows = () => import(/* webpackChunkName: "dev" */ '@/components/_dev/Shadows')
  DEV_COMPONENTS.DevIcons = () => import(/* webpackChunkName: "dev" */ '@/components/_dev/Icons')
  DEV_COMPONENTS.DevText = () => import(/* webpackChunkName: "dev" */ '@/components/_dev/Text')
  DEV_COMPONENTS.DevGrid = () => import(/* webpackChunkName: "dev" */ '@/components/_dev/Grid')
  DEV_COMPONENTS.DevControls = () => import(/* webpackChunkName: "dev" */ '@/components/_dev/Controls')
  DEV_COMPONENTS.DevComponents = () => import(/* webpackChunkName: "dev" */ '@/components/_dev/Components')
}

if (process.env.ENV_NAME === 'c1') {
  // Layout (included in core)

  COMPONENTS.Dragger = () => import(/* webpackChunkName: "base-layout" */ '@/components/public/Dragger')
  COMPONENTS.LazyIcon = () => import(/* webpackChunkName: "base-layout" */ '@/components/public/LazyIcon')
  COMPONENTS.HeaderAccount = () => import(/* webpackChunkName: "base-layout" */ '@/components/public/layout/HeaderAccount')
  COMPONENTS.HeaderBanner = () => import(/* webpackChunkName: "base-layout" */ '@/components/public/layout/HeaderBanner')
  COMPONENTS.HeaderLogo = () => import(/* webpackChunkName: "base-layout" */ '@/components/public/layout/HeaderLogo')
  COMPONENTS.HeaderLanguagenavigation = () => import(/* webpackChunkName: "base-layout" */ '@/components/public/layout/HeaderLanguagenavigation')
  COMPONENTS.HeaderSearch = () => import(/* webpackChunkName: "base-layout" */ '@/components/public/layout/HeaderSearch')
  COMPONENTS.HeaderShoppingcart = () => import(/* webpackChunkName: "base-layout" */ '@/components/public/layout/HeaderShoppingcart')
  COMPONENTS.HeaderUserMenu = () => import(/* webpackChunkName: "base-layout" */ '@/components/public/layout/HeaderUserMenu')
  COMPONENTS.HeaderWishlist = () => import(/* webpackChunkName: "base-layout" */ '@/components/public/layout/HeaderWishlist')

  COMPONENTS.GTM = () => import(/* webpackChunkName: "gtm" */ '@/components/public/special/GTM')
  COMPONENTS.GtmPromotionImpressions = () => import(/* webpackChunkName: "gtm" */ '@/components/public/special/GtmPromotionImpressions')

  // CMS-Components (included on demand)

  COMPONENTS.CmsIcons = () => import(/* webpackChunkName: "cms-icons" */ '@/components/public/cms/Icons')

  // Components (included on demand)

  COMPONENTS.CAccount = () => import(/* webpackChunkName: "account" */ '@/components/public/account/Account')
  COMPONENTS.CAccountCompany = () => import(/* webpackChunkName: "account" */ '@/components/public/account/AccountCompany')
  COMPONENTS.CAccountMenu = () => import(/* webpackChunkName: "account" */ '@/components/public/account/AccountMenu')
  COMPONENTS.CAccountOverviewOrders = () => import(/* webpackChunkName: "account" */ '@/components/public/account/AccountOverviewOrders')
  COMPONENTS.CAccountOrders = () => import(/* webpackChunkName: "account" */ '@/components/public/account/AccountOrders')
  COMPONENTS.CAccountOrder = () => import(/* webpackChunkName: "account" */ '@/components/public/account/AccountOrder')

  COMPONENTS.CAccordion = () => import(/* webpackChunkName: "content" */ '@/components/public/content/Accordion')
  COMPONENTS.CAccordionToggle = () => import(/* webpackChunkName: "content" */ '@/components/public/content/AccordionToggle')
  COMPONENTS.CAccordionContent = () => import(/* webpackChunkName: "content" */ '@/components/public/content/AccordionContent')
  COMPONENTS.CAnker = () => import(/* webpackChunkName: "content" */ '@/components/public/special/Anker')
  COMPONENTS.CScrollToAnkerLink = () => import(/* webpackChunkName: "content" */ '@/components/public/special/ScrollToAnkerLink')

  COMPONENTS.CBacklink = () => import(/* webpackChunkName: "content" */ '@/components/public/content/Backlink')
  COMPONENTS.CBasket = () => import(/* webpackChunkName: "basket" */ '@/components/public/order/Basket')
  COMPONENTS.CBasketDeliveryType = () => import(/* webpackChunkName: "basket" */ '@/components/public/order/BasketDeliveryType')
  COMPONENTS.CBasketResellerOptions = () => import(/* webpackChunkName: "basket" */ '@/components/public/order/BasketResellerOptions')
  COMPONENTS.CBreadcrumb = () => import(/* webpackChunkName: "content" */ '@/components/public/layout/Breadcrumb')

  COMPONENTS.CCarousel = () => import(/* webpackChunkName: "carousel" */ '@/components/public/content/Carousel')
  COMPONENTS.CCarouselWithPreview = () => import(/* webpackChunkName: "carousel" */ '@/components/public/content/CarouselWithPreview')
  COMPONENTS.CGridCarousel = () => import(/* webpackChunkName: "carousel" */ '@/components/public/content/GridCarousel')
  COMPONENTS.CProductTeaser = () => import(/* webpackChunkName: "carousel" */ '@/components/public/product/ProductTeaser')
  COMPONENTS.CTestimonialCarousel = () => import(/* webpackChunkName: "carousel" */ '@/components/public/content/TestimonialCarousel')

  COMPONENTS.CCheckoutNavigation = () => import(/* webpackChunkName: "checkout" */ '@/components/public/order/CheckoutNavigation')
  COMPONENTS.CCheckoutStep = () => import(/* webpackChunkName: "checkout" */ '@/components/public/order/CheckoutStep')
  COMPONENTS.CCheckoutOptionForm = () => import(/* webpackChunkName: "checkout" */ '@/components/public/order/CheckoutOptionForm')
  COMPONENTS.CCheckoutUserAddress = () => import(/* webpackChunkName: "checkout" */ '@/components/public/order/CheckoutUserAddress')
  COMPONENTS.CCheckoutUserAddresses = () => import(/* webpackChunkName: "checkout" */ '@/components/public/order/CheckoutUserAddresses')
  COMPONENTS.CCheckoutNewUserAddresses = () => import(/* webpackChunkName: "checkout" */ '@/components/public/order/CheckoutNewUserAddresses')
  COMPONENTS.CCheckoutCoupon = () => import(/* webpackChunkName: "checkout" */ '@/components/public/order/CheckoutCoupon')
  COMPONENTS.CColumns = () => import(/* webpackChunkName: "content" */ '@/components/public/layout/Columns')
  COMPONENTS.CContent = () => import(/* webpackChunkName: "content" */ '@/components/public/content/Content')
  COMPONENTS.CContentBlock = () => import(/* webpackChunkName: "content" */ '@/components/public/content/ContentBlock')
  COMPONENTS.CControlPasswordViewer = () => import(/* webpackChunkName: "controls" */ '@/components/public/forms/ControlPasswordViewer')
  COMPONENTS.CControlValidator = () => import(/* webpackChunkName: "controls" */ '@/components/public/forms/ControlValidator')
  COMPONENTS.CCookieBanner = () => import(/* webpackChunkName: "content" */ '@/components/public/layout/CookieBanner')
  COMPONENTS.CLoadMore = () => import(/* webpackChunkName: "content" */ '@/components/public/content/LoadMore')
  COMPONENTS.CVirtualPager = () => import(/* webpackChunkName: "content" */ '@/components/public/content/VirtualPager')

  COMPONENTS.CDiscoveryTeaser = () => import(/* webpackChunkName: "common" */ '@/components/public/content/DiscoveryTeaser')

  COMPONENTS.CEyecatcher = () => import(/* webpackChunkName: "common" */ '@/components/public/content/Eyecatcher')
  COMPONENTS.CEyecatcherIcon = () => import(/* webpackChunkName: "common" */ '@/components/public/content/EyecatcherIcon')
  COMPONENTS.CEyecatcherImage = () => import(/* webpackChunkName: "common" */ '@/components/public/content/EyecatcherImage')

  COMPONENTS.CFactBanner = () => import(/* webpackChunkName: "common" */ '@/components/public/content/FactBanner')
  COMPONENTS.CFactList = () => import(/* webpackChunkName: "common" */ '@/components/public/content/FactList')
  COMPONENTS.CFactItem = () => import(/* webpackChunkName: "common" */ '@/components/public/content/FactItem')
  COMPONENTS.CFallbackImage = () => import(/* webpackChunkName: "common" */ '@/components/public/special/FallbackImage')
  COMPONENTS.CForm = () => import(/* webpackChunkName: "controls" */ '@/components/public/forms/Form')
  COMPONENTS.CEvalancheForm = () => import(/* webpackChunkName: "controls" */ '@/components/public/forms/EvalancheForm')

  COMPONENTS.CGridMixed = () => import(/* webpackChunkName: "common" */ '@/components/public/content/GridMixed')
  COMPONENTS.CGridMixedCol = () => import(/* webpackChunkName: "common" */ '@/components/public/content/GridMixedCol')
  COMPONENTS.CGridShifted = () => import(/* webpackChunkName: "common" */ '@/components/public/content/GridShifted')

  COMPONENTS.CHandwriting = () => import(/* webpackChunkName: "content" */ '@/components/public/content/Handwriting')

  COMPONENTS.CIconButton = () => import(/* webpackChunkName: "content" */ '@/components/public/content/IconButton')
  COMPONENTS.CIconTileGrid = () => import(/* webpackChunkName: "content" */ '@/components/public/content/IconTileGrid')
  COMPONENTS.CIconTile = () => import(/* webpackChunkName: "content" */ '@/components/public/content/IconTile')
  COMPONENTS.CInfoContainer = () => import(/* webpackChunkName: "content" */ '@/components/public/content/InfoContainer')
  COMPONENTS.CInspirationTeaser = () => import(/* webpackChunkName: "common" */ '@/components/public/inspiration/InspirationTeaser')
  COMPONENTS.CInstagramFeed = () => import(/* webpackChunkName: "content" */ '@/components/public/content/InstagramFeed')
  COMPONENTS.CIntroBanner = () => import(/* webpackChunkName: "content" */ '@/components/public/content/IntroBanner')

  COMPONENTS.CNewsTeaser = () => import(/* webpackChunkName: "content" */ '@/components/public/news/NewsTeaser')

  COMPONENTS.COrder = () => import(/* webpackChunkName: "checkout" */ '@/components/public/order/Order')

  COMPONENTS.CProductVariantLoader = () => import(/* webpackChunkName: "product" */ '@/components/public/product/ProductVariantLoader')
  COMPONENTS.CProductImages = () => import(/* webpackChunkName: "product" */ '@/components/public/product/ProductImages')
  COMPONENTS.CProductShareButtons = () => import(/* webpackChunkName: "product" */ '@/components/public/product/ProductShareButtons')
  COMPONENTS.CProductAssemblytype = () => import(/* webpackChunkName: "product" */ '@/components/public/product/ProductAssemblytype')
  COMPONENTS.CProductButtongroup = () => import(/* webpackChunkName: "product" */ '@/components/public/product/ProductButtongroup')
  COMPONENTS.CProductCharacteristics = () => import(/* webpackChunkName: "product" */ '@/components/public/product/ProductCharacteristics')
  COMPONENTS.CProductColors = () => import(/* webpackChunkName: "product" */ '@/components/public/product/ProductColors')
  COMPONENTS.CProductDeliveryState = () => import(/* webpackChunkName: "product" */ '@/components/public/product/ProductDeliveryState')
  COMPONENTS.CProductDropdown = () => import(/* webpackChunkName: "product" */ '@/components/public/product/ProductDropdown')
  COMPONENTS.CProductInShowroom = () => import(/* webpackChunkName: "product" */ '@/components/public/product/ProductInShowroom')
  COMPONENTS.CProductItem = () => import(/* webpackChunkName: "product" */ '@/components/public/product/ProductItem')
  COMPONENTS.CProductPrice = () => import(/* webpackChunkName: "product" */ '@/components/public/product/ProductPrice')
  COMPONENTS.CProductShoppingcartButton = () => import(/* webpackChunkName: "product" */ '@/components/public/product/ProductShoppingcartButton')
  COMPONENTS.CProductWishlistButton = () => import(/* webpackChunkName: "product" */ '@/components/public/product/ProductWishlistButton')
  COMPONENTS.CProductDataHeader = () => import(/* webpackChunkName: "product" */ '@/components/public/product/ProductDataHeader')
  COMPONENTS.CProductData = () => import(/* webpackChunkName: "product" */ '@/components/public/product/ProductData')
  COMPONENTS.CProductVariantHandler = () => import(/* webpackChunkName: "product" */ '@/components/public/product/ProductVariantHandler')

  COMPONENTS.CProductConfigurator = () => import(/* webpackChunkName: "product-configurator" */ '@/components/public/product/ProductConfigurator')
  COMPONENTS.CNewProductConfigurator = () =>
    import(/* webpackChunkName: "product-configurator" */ '@/components/public/product/NewProductConfigurator')
  COMPONENTS.CSearch = () => import(/* webpackChunkName: "product-search" */ '@/components/public/special/Search')

  COMPONENTS.CReadMore = () => import(/* webpackChunkName: "content" */ '@/components/public/content/ReadMore')
  COMPONENTS.CRegistration = () => import(/* webpackChunkName: "account" */ '@/components/public/account/Registration')
  COMPONENTS.CRelatedProductsModal = () => import(/* webpackChunkName: "product" */ '@/components/public/product/RelatedProductsModal')
  COMPONENTS.CResponsiveImage = () => import(/* webpackChunkName: "common" */ '@/components/public/special/ResponsiveImage')
  COMPONENTS.CServiceTeaser = () => import(/* webpackChunkName: "common" */ '@/components/public/content/ServiceTeaser')
  COMPONENTS.CTestimonial = () => import(/* webpackChunkName: "common" */ '@/components/public/content/Testimonial')

  COMPONENTS.CVideo = () => import(/* webpackChunkName: "video-player" */ '@/components/public/content/Video')

  // StoreClerk Components
  COMPONENTS.CCheckoutCustomerSelection = () => import(/* webpackChunkName: "storeclerk" */ '@/components/public/order/CheckoutCustomerSelection')
  COMPONENTS.CSignaturePad = () => import(/* webpackChunkName: "storeclerk" */ '@/components/public/order/SignaturePad')
  COMPONENTS.COfferValidity = () => import(/* webpackChunkName: "storeclerk" */ '@/components/public/order/OfferValidity')
} else if (process.env.ENV_NAME === 'identityserver') {
  // Layout (included in core)

  COMPONENTS.HeaderBanner = () => import(/* webpackChunkName: "common" */ '@/components/public/layout/HeaderBanner')
  COMPONENTS.LazyIcon = () => import(/* webpackChunkName: "common" */ '@/components/public/LazyIcon')
  COMPONENTS.HeaderLogo = () => import(/* webpackChunkName: "common" */ '@/components/public/layout/HeaderLogo')
  COMPONENTS.HeaderLanguagenavigation = () => import(/* webpackChunkName: "common" */ '@/components/public/layout/HeaderLanguagenavigation')

  COMPONENTS.GTM = () => import(/* webpackChunkName: "gtm" */ '@/components/public/special/GTM')

  // Components (prefetched)

  COMPONENTS.CControlPasswordViewer = () => import(/* webpackChunkName: "controls" */ '@/components/public/forms/ControlPasswordViewer')
  COMPONENTS.CControlValidator = () => import(/* webpackChunkName: "controls" */ '@/components/public/forms/ControlValidator')

  COMPONENTS.CEyecatcherIcon = () => import(/* webpackChunkName: "common" */ '@/components/public/content/EyecatcherIcon')

  COMPONENTS.CFactBanner = () => import(/* webpackChunkName: "common" */ '@/components/public/content/FactBanner')
  COMPONENTS.CFactList = () => import(/* webpackChunkName: "common" */ '@/components/public/content/FactList')
  COMPONENTS.CFactItem = () => import(/* webpackChunkName: "common" */ '@/components/public/content/FactItem')
  COMPONENTS.CForm = () => import(/* webpackChunkName: "controls" */ '@/components/public/forms/Form')

  COMPONENTS.CRegistration = () => import(/* webpackChunkName: "controls" */ '@/components/public/account/Registration')
}

export default Object.assign({}, COMPONENTS, DEV_COMPONENTS)
