
let scrollerInitialized = false;

document.addEventListener('app:updated', () => {
  if (scrollerInitialized) {
    return;
  }

  const scrollComponents = document.querySelectorAll('.horizontal-scroller');
  scrollComponents.forEach(scrollComponent => {
    if (!scrollComponent) {
      return;
    }

    const scrollTarget = scrollComponent.querySelector('.scroll-target');

    if (!scrollTarget) {
      return;
    }

    scrollerInitialized = true;

    // Check if the scroll target has overflow
    if (scrollTarget.scrollWidth <= scrollTarget.clientWidth) {
      return;
    }

    const scrollLeftButton = scrollComponent.querySelector('.btn-scroll-left');
    const scrollRightButton = scrollComponent.querySelector('.btn-scroll-right');

    // Add .active class to left button if the scroll is not at the beginning
    const updateScrollButtonState = () => {
      if (scrollTarget.scrollLeft > 0) {
        scrollLeftButton.classList.add('active');
        scrollLeftButton.classList.remove('disabled');
        scrollLeftButton.disabled = false;
      } else {
        scrollLeftButton.classList.remove('active');
        scrollLeftButton.classList.add('disabled');
        scrollLeftButton.disabled = true;
      }

      if (scrollTarget.scrollLeft + scrollTarget.clientWidth < scrollTarget.scrollWidth) {
        scrollRightButton.classList.add('active');
        scrollRightButton.classList.remove('disabled');
        scrollRightButton.disabled = false;
      } else {
        scrollRightButton.classList.remove('active');
        scrollRightButton.classList.add('disabled');
        scrollRightButton.disabled = true;
      }
    };

    // initial scroll-button state update
    updateScrollButtonState();

    let scrollInterval;
    const scrollStep = 10;
    const scrollSpeed = 10;

    const scrollLeft = () => {
      scrollInterval = setInterval(() => {
        scrollTarget.scrollLeft -= scrollStep;
      }, scrollSpeed);
    }

    const scrollRight = () => {
      scrollInterval = setInterval(() => {
        scrollTarget.scrollLeft += scrollStep;
      }, scrollSpeed);
    }

    const stopScroll = () => {
      clearInterval(scrollInterval);
    }

    scrollLeftButton.addEventListener('mousedown', scrollLeft);
    scrollRightButton.addEventListener('mousedown', scrollRight);
    scrollLeftButton.addEventListener('mouseup', stopScroll);
    scrollRightButton.addEventListener('mouseup', stopScroll);
    scrollLeftButton.addEventListener('mouseleave', stopScroll);
    scrollRightButton.addEventListener('mouseleave', stopScroll);

    // Add event listener to scroll target to update scroll button state
    scrollTarget.addEventListener('scroll', updateScrollButtonState);
  });
});
