import { LOCALE_STORAGE_KEYS, USER_PROFILE_BASE_MODEL, USER_ROLE_CAN_RULES } from '@/constants'

import md5 from 'md5'
import { copy } from '@/assets/js/helper/object'

const user = getUser()

export default {
  namespaced: true,
  state: {
    profile: {
      user,
      loginState: getLoginState(user)
    }
  },
  getters: {
    get: state => state.profile,
    getUserId: state => state.profile.user.userId,
    getHashedUserId: state => state.profile.user.hashedId,
    getIsNewlyLoggedIn: state => state.profile.loginState.before === false && state.profile.loginState.now === true,
    can: state => rule => {
      const authoritativeRole = state.profile.user.roles
        .map(role => USER_ROLE_CAN_RULES[role] ?? {})
        .reverse()
        .find(role => role[rule] !== undefined) ?? {}

      return authoritativeRole[rule] ?? true
    }
  },
  mutations: {},
  actions: {}
}

function getUser () {
  const userProfile = window.userProfile || {}
  const hashedId = userProfile.userId ? md5(userProfile.userId) : null

  return Object.assign(copy(USER_PROFILE_BASE_MODEL), userProfile, { hashedId })
}

function getLoginState (user = {}) {
  const before = JSON.parse(window.localStorage.getItem(LOCALE_STORAGE_KEYS.loginstate)) ?? false
  window.localStorage.setItem(LOCALE_STORAGE_KEYS.loginstate, user.userId !== null)
  const now = JSON.parse(window.localStorage.getItem(LOCALE_STORAGE_KEYS.loginstate)) ?? false

  return { before, now }
}
