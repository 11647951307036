import {
  COMPONENT_SEARCH_TYPES,
  COMPONENT_SEARCH_FILTER_TYPES,
  COMPONENT_SEARCH_FILTER_MAPPER_TYPES_MAP,
  COMPONENT_SEARCH_FILTER_URL_PARAMETER_SEPARATOR
} from '@/constants'

import { getSearchParameters, setSearchParameters, getFullUrl } from '@/assets/js/helper/url'

import store from '@/$plugins/store/core'
import i18n from '@/$plugins/i18n/core'
import ASCIIFolder from 'fold-to-ascii'

const SEARCH = {
  isInitialized: false,
  initialParameters: [],
  extendedHiddenFilters: [],
  urlParameterKeys: []
}

export function filterIdGenerator(filter = {}) {
  return `${window.location.pathname}_${filter.field}`.replace(/\s/g, '_').replace(/[^a-zA-Z0-9-_]/g, '')
}

export function filterControlIdGenerator(filter = {}, control = {}) {
  return `${filter.field}_${control.key}`.replace(/\s/g, '_').replace(/[^a-zA-Z0-9-_]/g, '')
}

export function sortingIdGenerator(sorting = {}) {
  return `${window.location.pathname}_${sorting.field}_${sorting.isDescending ? 'descending' : 'ascending'}`
}

export function filterMapper(filter = {}, existingFilter = {}) {
  const CONFIG = getFilterControlConfig(filter)
  const COMPONENT_NAME = CONFIG.componentName
  const INITIAL_PARAMETER_VALUES = (SEARCH.initialParameters.find((p) => p.key === filter.key) || {}).values || []

  return {
    id: filterIdGenerator(filter),
    field: filter.field,
    key: filter.key,
    type: filter.type,
    component: COMPONENT_NAME,
    sortOrder: filter.sortOrder,
    displayName: (filter.displayName || {})[store.getters['gui/language:get']],
    controls: getControls()
  }

  function getControls() {
    let controls = [
      {
        type: 'unknown',
        message: `The type '${filter.type}' of filter '${filter.field}' is unknown! You may want to define mapping logic for this type of filters in '@/assets/js/helper/search.js'.`
      }
    ]

    if (COMPONENT_SEARCH_FILTER_MAPPER_TYPES_MAP.text.includes(filter.type)) {
      const CONTROL_ID = filterControlIdGenerator(filter)
      const EXISTING_CONTROL = (existingFilter.controls || []).find((c) => c.id === CONTROL_ID) || {}
      const CONTROL_VALUE = INITIAL_PARAMETER_VALUES[0] || CONFIG.controls.text.emptyValue

      controls = [
        Object.assign(
          {
            id: CONTROL_ID,
            type: 'text',
            value: CONTROL_VALUE
          },
          EXISTING_CONTROL
        )
      ]
    } else if (COMPONENT_SEARCH_FILTER_MAPPER_TYPES_MAP.items.includes(filter.type)) {
      controls = filter.items.map((control) => {
        const CONTROL_ID = filterControlIdGenerator(filter, control)
        const EXISTING_CONTROL = (existingFilter.controls || []).find((c) => c.id === CONTROL_ID) || {}
        const CONTROL_VALUE =
          INITIAL_PARAMETER_VALUES.includes(ASCIIFolder.foldMaintaining(control.key.toLowerCase())) || CONFIG.controls.item.emptyValue
        const DATA = Object.keys(control)
          .filter((pKey) => !['displayName', 'key', 'count'].includes(pKey))
          .reduce((data, pKey) => Object.assign(data, { [pKey]: control[pKey] }), {})

        return Object.assign(
          {
            id: CONTROL_ID,
            key: control.key,
            type: 'item',
            text: control.displayName,
            value: CONTROL_VALUE,
            data: DATA
          },
          EXISTING_CONTROL,
          { count: control.count }
        )
      })
    } else if (COMPONENT_SEARCH_FILTER_MAPPER_TYPES_MAP.range.includes(filter.type)) {
      controls = [
        { key: 'minValue', value: filter.minValue },
        { key: 'maxValue', value: filter.maxValue }
      ].map((control, cIndex) => {
        const CONTROL_ID = filterControlIdGenerator(filter, control)
        const EXISTING_CONTROL = (existingFilter.controls || []).find((c) => c.id === CONTROL_ID) || {}
        const CONTROL_VALUE = INITIAL_PARAMETER_VALUES[cIndex] || control.value

        return Object.assign(
          {
            id: CONTROL_ID,
            key: control.key,
            type: control.key,
            value: CONTROL_VALUE,
            baseValue: control.value,
            [control.key]: control.value,
            unit: CONFIG.controls[control.key].unit || ''
          },
          EXISTING_CONTROL
        )
      })
    } else if (COMPONENT_SEARCH_FILTER_MAPPER_TYPES_MAP.hidden.includes(filter.type)) {
      const CONTROL_ID = filterControlIdGenerator(filter)
      const EXISTING_CONTROL = (existingFilter.controls || []).find((c) => c.id === CONTROL_ID) || {}
      const CONTROL_VALUE = INITIAL_PARAMETER_VALUES[0] || CONFIG.controls.hidden.emptyValue

      controls = [
        Object.assign(
          {
            id: CONTROL_ID,
            type: 'hidden',
            value: CONTROL_VALUE
          },
          EXISTING_CONTROL
        )
      ]
    }

    controls.forEach((c) => {
      if (c.type === 'unknown') console.warn(c.message)
    })

    return controls
  }
}

export function sortingMapper(sorting = {}, existingSorting = {}) {
  return Object.assign(
    {
      id: sortingIdGenerator(sorting),
      field: sorting.field,
      sortOrder: sorting.sortOrder,
      isDefault: sorting.isDefault,
      isDescending: sorting.isDescending,
      displayName: (sorting.displayName || {})[store.getters['gui/language:get']],
      isActive: sorting.isDefault
    },
    existingSorting
  )
}

export function filterControlResetter(filter = {}) {
  const CONFIG = getFilterControlConfig(filter)

  return filter.controls
    .filter((c) => c.type !== 'unknown')
    .map((c) => {
      if (COMPONENT_SEARCH_FILTER_MAPPER_TYPES_MAP.range.includes(filter.type)) {
        c.value = c.baseValue
      } else if (COMPONENT_SEARCH_FILTER_MAPPER_TYPES_MAP.hidden.includes(filter.type)) {
        // this is not resettable
      } else {
        c.value = CONFIG.controls[c.type].emptyValue
      }

      return c
    })
}

export function resetInitialFilters() {
  const hiddenFilters = extendedHiddenFiltersGetter()
  SEARCH.initialParameters = SEARCH.initialParameters.filter((p) => hiddenFilters.includes(p.key))
}

export function resetInitialFilter(filter) {
  const initialParameterIndex = SEARCH.initialParameters.findIndex((p) => p.key === filter.key)
  const hiddenFilters = extendedHiddenFiltersGetter()
  if (initialParameterIndex >= 0 && !hiddenFilters.includes(filter.key)) {
    SEARCH.initialParameters.splice(initialParameterIndex, 1)
  }
}

export function activeFiltersMapper(filters = []) {
  return filters
    .filter((filter) => {
      const CONFIG = getFilterControlConfig(filter)

      if (COMPONENT_SEARCH_FILTER_MAPPER_TYPES_MAP.range.includes(filter.type)) {
        return filter.controls.some((c) => c.value !== c.baseValue)
      } else if (COMPONENT_SEARCH_FILTER_MAPPER_TYPES_MAP.hidden.includes(filter.type)) {
        return false
      } else {
        return CONFIG !== undefined && filter.controls.some((c) => c.value !== CONFIG.controls[c.type].emptyValue)
      }
    })
    .map((filter) => {
      const CONFIG = getFilterControlConfig(filter)
      const controls = filter.controls
        .filter((c) => {
          if (COMPONENT_SEARCH_FILTER_MAPPER_TYPES_MAP.range.includes(filter.type)) {
            return true
          } else {
            return c.value !== CONFIG.controls[c.type].emptyValue
          }
        })
        .map((c) => {
          if (COMPONENT_SEARCH_FILTER_MAPPER_TYPES_MAP.items.includes(filter.type)) {
            return c.text
          } else if (COMPONENT_SEARCH_FILTER_MAPPER_TYPES_MAP.range.includes(filter.type)) {
            return `${i18n.n(Number(c.value), 'decimal')} ${c.unit}`
          } else {
            return c.value
          }
        })

      return Object.assign({}, filter, { controls })
    })
}

export function searchParameters({ filters = [], sortings = [], statistics = {} }, searchType) {
  return {
    filters: filterParameters(filters, searchType),
    sortings: sortingParameters(sortings, searchType),
    page: pageParameters(statistics, searchType),
    extendedHiddenFilters: extendedHiddenFiltersGetter()
  }
}

export function getFiltersMergedWithInitial(filters = []) {
  const initialParameters = SEARCH.initialParameters
  const parameterKeys = Array.from(new Set([].concat(filters, initialParameters).map((p) => p.key)))

  return parameterKeys.map((pKey) => filters.find((p) => p.key === pKey) || initialParameters.find((p) => p.key === pKey))
}

export function filterParameters(filters = [], searchType) {
  const filterParameterBase = !SEARCH.isInitialized ? initialParameterGetter() : getFiltersMergedWithInitial(filters)

  SEARCH.isInitialized = true

  return filterParameterBase
    .map((filter) => {
      return {
        key: filter.key,
        field: filter.field,
        values: getFilterValues(filter)
      }
    })
    .filter((filter) => filter.values.length > 0)

  function getFilterValues(filter = {}) {
    const CONFIG = getFilterControlConfig(filter)
    let controls = []

    // this is for allready mapped filters
    if (filter.controls) {
      controls = filter.controls
        .filter((c) => c.type !== 'unknown')
        .filter((c) => {
          if (COMPONENT_SEARCH_FILTER_MAPPER_TYPES_MAP.range.includes(filter.type)) {
            return filter.controls.some((c) => c.value !== c.baseValue)
          } else {
            return c.value !== CONFIG.controls[c.type].emptyValue
          }
        })
        .map((c) => {
          if (COMPONENT_SEARCH_FILTER_MAPPER_TYPES_MAP.items.includes(filter.type)) {
            return c.key
          } else {
            return c.value
          }
        })
        .flat()

      // this is for initial filterParameters
    } else if (filter.values) {
      controls = filter.values.map((v) => v || null)
    }

    return controls
  }
}

export function sortingParameters(sortings = [], searchType) {
  /* eslint-disable */
  return sortings.length <= 0
    ? null
    : sortings
        .filter((sorting) => sorting.isActive)
        .map((sorting) => ({
          field: sorting.field,
          descending: sorting.isDescending
        }))
}

export function pageParameters(statistics = {}, searchType) {
  const pageStats = statistics.page || {}

  return {
    number: pageStats.number || COMPONENT_SEARCH_TYPES[searchType].paging.page,
    size: pageStats.size || COMPONENT_SEARCH_TYPES[searchType].paging.pageSize
  }
}

export function initialParameterGetter() {
  return SEARCH.initialParameters
}

export function initialParameterSetter(initialParameters = [], extendedHiddenFilters = []) {
  const initialUrlParameters = getBaseUrlParameters()

  const PARAMETER_KEYS = Array.from(new Set([].concat(initialUrlParameters, initialParameters).map((p) => p.key)))
  const INITIAL_PARAMETERS = PARAMETER_KEYS.map(
    (pKey) => initialUrlParameters.find((p) => p.key === pKey) || initialParameters.find((p) => p.key === pKey)
  )

  urlParameterKeySetter(INITIAL_PARAMETERS.filter((x) => !extendedHiddenFilters.includes(x.key)))
  SEARCH.extendedHiddenFilters = extendedHiddenFilters
  SEARCH.initialParameters = INITIAL_PARAMETERS.map((f) => ({
    key: f.key,
    values: f.values || []
  }))
}

export function extendedHiddenFiltersGetter() {
  return SEARCH.extendedHiddenFilters
}

export function urlParameterGetter() {
  return getBaseUrlParameters().filter((p) => SEARCH.urlParameterKeys.includes(p.key))
}

export function urlParameterKeySetter(filterParameters = []) {
  SEARCH.urlParameterKeys = filterParameters.map((f) => f.key)
}

export function urlParameterSetter(filterParameters = []) {
  const FILTER_PARAMETER = filterParameters.filter((p) => {
    const filter = store.getters['search/filters'].find((f) => f.key === p.key) || {}
    return !COMPONENT_SEARCH_FILTER_MAPPER_TYPES_MAP.hidden.includes(filter.type)
  })

  const parameterArray = {}
  FILTER_PARAMETER.filter((p) => !SEARCH.extendedHiddenFilters.includes(p.key)).forEach((fp) => {
    parameterArray[fp.key] = fp.values
  })

  const URL_PARAMETERS = ASCIIFolder.foldMaintaining(setSearchParameters(parameterArray))
  const FULL_URL = getFullUrl(URL_PARAMETERS)

  window.history.replaceState(null, null, FULL_URL || window.location.pathname)
}

export function fieldToKeyConverter(field = '') {
  return field.replace(/(-[a-z]{2})?\..*$/, '')
}

export function resultMapper(results = [], searchType) {
  return results.map(COMPONENT_SEARCH_TYPES[searchType].result.mapper || ((r) => r))
}

function getFilterControlConfig(filter = {}) {
  return Object.values(COMPONENT_SEARCH_FILTER_TYPES).find((t) => t.type === filter.type) || COMPONENT_SEARCH_FILTER_TYPES.unknown
}

function getBaseUrlParameters() {
  return getSearchParameters().map((p) => ({
    key: p.key,
    values: ASCIIFolder.foldMaintaining(p.value).split(COMPONENT_SEARCH_FILTER_URL_PARAMETER_SEPARATOR)
  }))
}

export default {
  filterIdGenerator,
  filterControlIdGenerator,
  sortingIdGenerator,
  filterMapper,
  sortingMapper,
  filterControlResetter,
  activeFiltersMapper,
  searchParameters,
  filterParameters,
  sortingParameters,
  pageParameters,
  initialParameterGetter,
  initialParameterSetter,
  urlParameterKeySetter,
  urlParameterGetter,
  urlParameterSetter,
  fieldToKeyConverter,
  resultMapper
}
