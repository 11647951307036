import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue/esm/index.js'
import { IconsPlugin } from './icons'

const Bootstrap = Vue.use(BootstrapVue)
const BootstrapIcons = Vue.use(IconsPlugin)

export {
  Bootstrap as default,
  BootstrapIcons
}
