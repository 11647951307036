import { PAGINATION_INITIAL_VALUES } from '@/constants'

import Vue from 'vue'
import http from '@/$plugins/http'

import { normalize, unwrap } from '@/assets/js/helper/entity'
import base from './index'

export default {
  state: {
    promises: {
      definition: null
    },
    definition: {
      properties: [],
      $properties: []
    },
    statistics: {
      page: {
        number: PAGINATION_INITIAL_VALUES.page,
        size: PAGINATION_INITIAL_VALUES.pageSize
      },
      total: {
        items: 0,
        pages: 0
      }
    },
    self: normalize({}),
    entities: []
  },
  getters: {
    definition: state => state.definition,
    statistics: state => state.statistics,
    get: state => state.entities,
    getUnwrapped: state => state.entities.map(unwrap),
    getSelf: state => state.self,
    getSelfUnwrapped: state => unwrap(state.self),
    getEntity: state => entityId => state.entities.find(e => e.value.id === entityId),
    getEntityUnwrapped: state => entityId => unwrap(state.entities.find(e => e.value.id === entityId))
  },
  mutations: {
    setDefinition (state, definition = {}) {
      state.definition = Object.assign({}, definition, { $properties: definition.properties.reduce((properties, p) => Object.assign(properties, { [p.name]: p }), {}) })
    },
    setStatistics (state, statistics = {}) {
      state.statistics.page.number = statistics.pageNumber
      state.statistics.page.size = statistics.pageSize
      state.statistics.total.items = statistics.totalItems
      state.statistics.total.pages = statistics.totalPages
    },
    set (state, { key, entities = [] }) {
      state.entities = entities
        .map(entity => {
          const normalizedEntity = normalize(entity, key)
          if (normalizedEntity.value.id === state.self.value.id) state.self = normalizedEntity
          return normalizedEntity
        })
    },
    setSelf (state, { key, entity = {} }) {
      state.self = normalize(entity, key)
    },
    setEntity (state, { key, entity = {} }) {
      const normalizedEntity = normalize(entity, key)
      const updateIndex = state.entities.findIndex(e => e.value.id === normalizedEntity.value.id)
      const newIndex = state.entities.length

      if (normalizedEntity.value.id === state.self.value.id) state.self = normalizedEntity
      Vue.set(state.entities, updateIndex >= 0 ? updateIndex : newIndex, normalizedEntity)
    },
    removeEntity (state, { key, entity = {} }) {
      const normalizedEntity = normalize(entity, key)
      const removeIndex = state.entities.findIndex(e => e.value.id === normalizedEntity.value.id)

      if (normalizedEntity.value.id === state.self.value.id) state.self = normalize({})
      Vue.delete(state.entities, removeIndex)
    }
  },
  actions: {
    getDefinition (serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/getDefinition`

      return function ({ state, getters, commit }) {
        commit('setLoading', { key: isKey, loading: true, initial: state.definition.id === undefined })

        if (state.promises.definition) {
          // return existing, unresolved Promise (resolves, as soon as the existing Promise resolves)
          return state.promises.definition
        } else if (state.definition.id !== undefined) {
          // return new, resolved Promise (with existing data)
          return Promise
            .resolve(getters.definition)
            .finally(() => {
              commit('setLoading', { key: isKey, loading: false })
            })
        } else {
          // return new, unresolved Promise
          state.promises.definition = new Promise((resolve, reject) => {
            http({
              method: 'get',
              url: `/customer/api/${serverEntityName}/definition`
            })
              .then(response => {
                commit('setDefinition', response.data.result)
                resolve(getters.definition)
              })
              .catch(reject)
              .finally(() => {
                commit('setLoading', { key: isKey, loading: false })
                state.promises.definition = null
              })
          })

          return state.promises.definition
        }
      }
    },
    getAll (serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/getAll`

      return function ({ state, getters, commit, dispatch }, options = {}) {
        const o = base.getOptions(options)

        dispatch('getDefinition')
        commit('setLoading', { key: isKey, loading: true, initial: true })

        return new Promise((resolve, reject) => {
          http({
            method: 'get',
            url: `/customer/api/${serverEntityName}${o.by.url.from}/all`,
            params: o.data
          })
            .then(response => {
              commit('set', { key: entityKey, entities: response.data.result })
              resolve(getters.get)
            })
            .catch(reject)
            .finally(() => {
              commit('setLoading', { key: isKey, loading: false })
            })
        })
      }
    },
    get (serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/get`

      return function ({ state, getters, commit, dispatch }, options = {}) {
        const o = base.getOptions(options, {
          page: state.statistics.page.number,
          pageSize: state.statistics.page.size
        })

        dispatch('getDefinition')
        commit('setLoading', { key: isKey, loading: true, initial: state.entities.length === 0 })

        return new Promise((resolve, reject) => {
          http({
            method: 'get',
            url: `/customer/api/${serverEntityName}${o.by.url.from}`,
            params: o.data
          })
            .then(response => {
              commit('setStatistics', response.data)
              commit('set', { key: entityKey, entities: response.data.result })
              resolve(getters.get)
            })
            .catch(reject)
            .finally(() => {
              commit('setLoading', { key: isKey, loading: false })
            })
        })
      }
    },
    getPage (serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = { by: {}, data: {}, number: undefined, action: undefined }) {
        if (options.number !== undefined) {
          state.statistics.page.number = options.number
          if (options.action !== false) dispatch(options.action !== undefined ? options.action : 'get', { by: options.by || {}, data: options.data || {} })
        }
      }
    },
    getSelf (serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/getSelf`

      return function ({ state, getters, commit, dispatch }) {
        dispatch('getDefinition')
        commit('setLoading', { key: isKey, loading: true, initial: state.entities.length === 0 })

        return new Promise((resolve, reject) => {
          http({
            method: 'get',
            url: `/customer/api/${serverEntityName}/self`
          })
            .then(response => {
              commit('setSelf', { key: entityKey, entity: response.data.result })
              commit('setEntity', { key: entityKey, entity: response.data.result })
              resolve(getters.getSelf)
            })
            .catch(reject)
            .finally(() => {
              commit('setLoading', { key: isKey, loading: false })
            })
        })
      }
    },
    getEntity (serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/getEntity`

      return function ({ state, getters, commit, dispatch }, entityId = null) {
        dispatch('getDefinition')
        commit('setLoading', { key: isKey, loading: true, initial: state.entities.length === 0 })

        return new Promise((resolve, reject) => {
          http({
            method: 'get',
            url: `/customer/api/${serverEntityName}/${entityId}`
          })
            .then(response => {
              commit('setEntity', { key: entityKey, entity: response.data.result })
              resolve(getters.getEntity(entityId))
            })
            .catch(reject)
            .finally(() => {
              commit('setLoading', { key: isKey, loading: false })
            })
        })
      }
    },
    createEntity (serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/createEntity`

      return function ({ state, getters, commit, dispatch }, entity = {}) {
        commit('setSending', { key: isKey, sending: true })

        return new Promise((resolve, reject) => {
          http({
            method: 'post',
            url: `/customer/api/${serverEntityName}`,
            data: unwrap(entity)
          })
            .then(response => {
              const ENTITY = unwrap(response.data.result)

              commit('setEntity', { key: entityKey, entity: ENTITY })
              resolve(getters.getEntity(ENTITY.id))
            })
            .catch(reject)
            .finally(() => {
              commit('setSending', { key: isKey, sending: false })
            })
        })
      }
    },
    updateEntity (serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/updateEntity`

      return function ({ state, getters, commit, dispatch }, entity = {}) {
        commit('setSending', { key: isKey, sending: true })

        const ENTITY = unwrap(entity)

        return new Promise((resolve, reject) => {
          http({
            method: 'put',
            url: `/customer/api/${serverEntityName}/${ENTITY.id}`,
            data: ENTITY
          })
            .then(response => {
              commit('setEntity', { key: entityKey, entity: response.data.result })
              resolve(getters.getEntity(ENTITY.id))
            })
            .catch(reject)
            .finally(() => {
              commit('setSending', { key: isKey, sending: false })
            })
        })
      }
    },
    removeEntity (serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/removeEntity`

      return function ({ state, getters, commit, dispatch }, entity = {}) {
        commit('setSending', { key: isKey, sending: true })

        const ENTITY = unwrap(entity)

        return new Promise((resolve, reject) => {
          http({
            method: 'delete',
            url: `/customer/api/${serverEntityName}/${ENTITY.id}`
          })
            .then(response => {
              commit('removeEntity', { key: entityKey, entity })
              resolve()
            })
            .catch(reject)
            .finally(() => {
              commit('setSending', { key: isKey, sending: false })
            })
        })
      }
    }
  }
}
