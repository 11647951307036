import { LOCALE_STORAGE_KEYS } from '@/constants'

import Vue from 'vue'
import store from '@/$plugins/store/core'
import http from '@/$plugins/http'

import base from './base'
import IS from './base/is'

const LOCALE_STORAGE_KEYS_DEFINITION = {
  id: `${LOCALE_STORAGE_KEYS.wishlistid}`,
  count: `${LOCALE_STORAGE_KEYS.wishlistcount}`,
  itemIds: `${LOCALE_STORAGE_KEYS.wishlistitemids}`
}

let getMinimalAbortController
let getAbortController

export default {
  namespaced: true,
  state: base.createState(IS.state, {
    type: 'minimal',
    basket: {
      id: window.localStorage.getItem(LOCALE_STORAGE_KEYS_DEFINITION.id),
      itemCount: Number(window.localStorage.getItem(LOCALE_STORAGE_KEYS_DEFINITION.count) ?? 0),
      containedItemExternalIds: [],
      lastAction: null
    },
    localItemConfiguration: []
  }),
  getters: base.createGetters(IS.getters, {
    getType: state => state.type,
    get: state => state.basket,
    getId: state => state.basket.id,
    getItemCount: state => state.basket.itemCount,
    getItemIds: state => state.basket.containedItemExternalIds,
    getLastAction: state => state.basket.lastAction || {},
    getLocalItemConfiguration: state => productId => state.localItemConfiguration.find(c => c.productId === productId)
  }),
  mutations: base.createMutations(IS.mutations, {
    set (state, { basket, merge, omitLastAction }) {
      if (omitLastAction) delete basket.lastAction
      state.basket = merge ? Object.assign(state.basket, basket) : basket
      window.localStorage.setItem(LOCALE_STORAGE_KEYS_DEFINITION.id, state.basket.id)
      window.localStorage.setItem(LOCALE_STORAGE_KEYS_DEFINITION.count, state.basket.itemCount)
      window.localStorage.setItem(LOCALE_STORAGE_KEYS_DEFINITION.itemIds, state.basket.containedItemExternalIds)
    },
    setLocalItemConfiguration (state, configuration = {}) {
      const updateIndex = state.localItemConfiguration.findIndex(c => c.productId === configuration.productId)
      const newIndex = state.localItemConfiguration.length
      const index = updateIndex >= 0 ? updateIndex : newIndex
      const base = state.localItemConfiguration[index] || {}

      Vue.set(state.localItemConfiguration, index, Object.assign(base, configuration))
    },
    setIdFromStorage (state, id = null) {
      if (id !== null && state.basket.id !== id) {
        state.basket.id = id
      }
    },
    setItemCountFromStorage (state, count = null) {
      const itemCount = Number(count)
      if (!isNaN(itemCount) && state.basket.itemCount !== itemCount) {
        state.basket.itemCount = itemCount
      }
    },
    setItemIdsFromStorage (state, itemIds = []) {
      const currentState = state.basket.containedItemExternalIds ?? null
      if (currentState === null || currentState.length !== itemIds.length) {
        state.basket.containedItemExternalIds = itemIds
      }
    }
  }),
  actions: base.createActions(IS.actions, {
    getMinimal ({ state, getters, commit, dispatch, rootGetters }) {
      if (getMinimalAbortController) getMinimalAbortController.abort()
      getMinimalAbortController = new AbortController()

      const isKey = 'Wishlist/getMinimal'
      commit('setLoading', { key: isKey, loading: true, initial: state.basket.basketState === undefined })

      return new Promise((resolve, reject) => {
        http({
          method: 'get',
          url: `/order/api/Wishlist/small${getters.getId ? `/${getters.getId}` : ''}?lang=${rootGetters['gui/language:get']}`,
          signal: getMinimalAbortController.signal
        })
          .then(response => {
            if (response.data) {
              commit('set', { basket: response.data.result, merge: true })
              resolve(getters.get)
            }
          })
          .catch(reject)
          .finally(() => {
            commit('setLoading', { key: isKey, loading: false })
          })
      })
    },
    get ({ state, getters, commit, dispatch, rootGetters }) {
      if (getAbortController) getAbortController.abort()
      getAbortController = new AbortController()

      const isKey = 'Wishlist/get'
      state.type = 'full'
      commit('setLoading', { key: isKey, loading: true, initial: state.basket.basketState === undefined })

      return new Promise((resolve, reject) => {
        http({
          method: 'get',
          url: `/order/api/Wishlist${getters.getId ? `/${getters.getId}` : ''}?lang=${rootGetters['gui/language:get']}`,
          signal: getAbortController.signal
        })
          .then(response => {
            if (response.data) {
              commit('set', { basket: response.data.result, merge: false })
              resolve(getters.get)
            }
          })
          .catch(reject)
          .finally(() => {
            commit('setLoading', { key: isKey, loading: false })
          })
      })
    },
    update ({ state, getters, commit, dispatch, rootGetters }, basketConfiguration = {}) {
      const isKey = 'Wishlist/update'
      commit('setSending', { key: isKey, sending: true })

      return new Promise((resolve, reject) => {
        http({
          method: 'put',
          url: `/order/api/Wishlist/${getters.getId}?lang=${rootGetters['gui/language:get']}`,
          data: basketConfiguration
        })
          .then(response => {
            commit('set', { basket: response.data.result, merge: false })
            resolve(getters.get)
          })
          .catch(reject)
          .finally(() => {
            commit('setSending', { key: isKey, sending: false })
          })
      })
    },
    delete ({ state, getters, commit, dispatch, rootGetters }) {
      const isKey = 'Wishlist/delete'
      commit('setSending', { key: isKey, sending: true })

      return new Promise((resolve, reject) => {
        http({
          method: 'delete',
          url: `/order/api/Wishlist/${getters.getId}?lang=${rootGetters['gui/language:get']}`
        })
          .then(response => {
            commit('set', { basket: response.data.result, merge: false })
            resolve(getters.get)
          })
          .catch(reject)
          .finally(() => {
            commit('setSending', { key: isKey, sending: false })
          })
      })
    },
    addItem ({ state, getters, commit, dispatch, rootGetters }, { productId = '', configuration = {}, gtmList = 'Category', omitLastAction = false }) {
      const isKey = 'Wishlist/addItem'
      commit('setLoading', { key: isKey, loading: true, initial: state.basket.basketState === undefined })

      const localItemConfiguration = state.localItemConfiguration.find(c => c.productId === productId) ?? {}
      const itemConfiguration = Object.assign(localItemConfiguration, configuration)

      return new Promise((resolve, reject) => {
        http({
          method: 'put',
          url: `/order/api/Wishlist/${getters.getId}/items/add/${productId}?lang=${rootGetters['gui/language:get']}`,
          data: itemConfiguration
        })
          .then(response => {
            dispatch('gtm/addToWishlist', {
              list: gtmList,
              product: Object.assign({}, response.data.result.lastAction.product, { quantity: response.data.result.lastAction.quantityChange })
            }, { root: true })
            commit('set', { basket: response.data.result, merge: false, omitLastAction })
            resolve(getters.get)
          })
          .catch(reject)
          .finally(() => {
            commit('setLoading', { key: isKey, loading: false })
          })
      })
    },
    deleteItem ({ state, getters, commit, dispatch, rootGetters }, { item = {}, gtmList = 'Category', omitLastAction = false }) {
      const isKey = 'Wishlist/addItem'
      commit('setSending', { key: isKey, sending: true })

      return new Promise((resolve, reject) => {
        http({
          method: 'delete',
          url: `/order/api/Wishlist/${getters.getId}/items/${item.id}/delete?lang=${rootGetters['gui/language:get']}`
        })
          .then(response => {
            dispatch('gtm/removeFromWishlist', {
              list: gtmList,
              product: Object.assign({}, response.data.result.lastAction.product, { quantity: response.data.result.lastAction.quantityChange })
            }, { root: true })
            commit('set', { basket: response.data.result, merge: false, omitLastAction })
            resolve()
          })
          .catch(reject)
          .finally(() => {
            commit('setSending', { key: isKey, sending: false })
          })
      })
    }
  })
}

window.addEventListener('storage', e => {
  if (Object.values(LOCALE_STORAGE_KEYS_DEFINITION).includes(e.key)) {
    if (e.key === LOCALE_STORAGE_KEYS_DEFINITION.id) store.commit('wishlist/setIdFromStorage', e.newValue)
    if (e.key === LOCALE_STORAGE_KEYS_DEFINITION.count) store.commit('wishlist/setItemCountFromStorage', e.newValue)
    if (e.key === LOCALE_STORAGE_KEYS_DEFINITION.itemIds) store.commit('wishlist/setItemIdsFromStorage', e.newValue)
  }
})
