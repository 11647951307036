<script>
import { BREAKPOINTS } from '@/assets/js/modules/scss-variables';
import debounce from 'lodash/debounce';

const MOBILEFIRST_BREAKPOINT_MAP = Object.keys(BREAKPOINTS.mobilefirst.breakpoints).map(bKey => ({ key: bKey, value: BREAKPOINTS.mobilefirst.breakpoints[bKey] })).reverse()
const DESKTOPFIRST_BREAKPOINT_MAP = Object.keys(BREAKPOINTS.desktopfirst.breakpoints).map(bKey => ({ key: bKey, value: BREAKPOINTS.desktopfirst.breakpoints[bKey] }))

export default {
  data() {
    return {
      viewport: {
        width: window.innerWidth,
        height: window.innerHeight
      },
      page: {
        loaded: false,
        height: document.documentElement.scrollHeight,
        scrollTop: document.documentElement.scrollTop,
        scrollBottom: document.documentElement.scrollTop + window.innerHeight,
        scrollDirectionUpdate: true,
        scrollDirection: null,
        scrollingDown: false,
        scrollingUp: false
      },
      header: {
        height: null,
        inViewportHeight: null
      },
      // mobilefirst tablet { key, value } shorthand
      mt: BREAKPOINTS.mobilefirst.tablet,
      // mobilefirst desktop { key, value } shorthand
      md: BREAKPOINTS.mobilefirst.desktop,
      // desktopfirst tablet { key, value } shorthand
      dt: BREAKPOINTS.desktopfirst.tablet,
      // desktopfirst mobile { key, value } shorthand
      dm: BREAKPOINTS.desktopfirst.mobile
    }
  },
  computed: {
    mobilefirstBreakpoint() {
      return MOBILEFIRST_BREAKPOINT_MAP.find(b => b.value <= this.viewport.width);
    },
    desktopfirstBreakpoint() {
      return DESKTOPFIRST_BREAKPOINT_MAP.find(b => b.value >= this.viewport.width);
    },
    isMobile() {
      return this.viewport.width < BREAKPOINTS.mobilefirst.tablet.value;
    },
    isTablet() {
      return this.viewport.width >= BREAKPOINTS.mobilefirst.tablet.value && this.viewport.width < BREAKPOINTS.mobilefirst.desktop.value;
    },
    isDesktop() {
      return this.viewport.width > BREAKPOINTS.desktopfirst.tablet.value;
    }
  },
  methods: {
    onLoad() {
      this.updateData();
      this.updateScrolldirection();
      this.updateScrollposition();
      this.updateHeaderHeight();
      this.page.loaded = true;
    },
    updateOnScroll() {
      this.updateScrolldirection();
      this.updateScrollposition();
      this.updateHeaderHeight();
      this.updateData();
    },
    updateData() {
      this.viewport.width = window.innerWidth;
      this.viewport.height = window.innerHeight;

      if (this.page.scrollDirectionUpdate) this.setScrollDirection();

      this.page.height = document.documentElement.scrollHeight;
      this.page.scrollTop = document.documentElement.scrollTop;
      this.page.scrollBottom = document.documentElement.scrollTop + window.innerHeight;
    },
    setScrollDirection() {
      this.page.scrollDirectionUpdate = false;
      setTimeout(() => { this.page.scrollDirectionUpdate = true; }, 500);

      const resetScrollDirection = this.page.loaded || document.documentElement.scrollTop === 0;
      this.page.scrollDirection = resetScrollDirection ? (this.page.scrollTop > document.documentElement.scrollTop ? 'scrolling-up' : 'scrolling-down') : null;
    },
    updateScrollposition() {
      const existingClasses = Array.from(document.documentElement.classList);
      var addClasses = [];
      const removeClasses = ['scroll-top', 'scroll-bottom', 'scrolling-in-between', 'hide-header-nav'];

      if (this.page.scrollTop === 0) {
        addClasses.push('scroll-top');
      } else if (this.page.scrollBottom === this.page.height) {
        addClasses.push('scroll-bottom');
      } else {
        addClasses.push('scrolling-in-between');
      }

      if (this.page.scrollTop !== 0 && this.page.scrollingDown) {
        addClasses.push('hide-header-nav');
      }

      const finalRemoveClasses = removeClasses.filter(c => !addClasses.includes(c) && existingClasses.includes(c));
      const finalAddClasses = addClasses.filter(c => !existingClasses.includes(c));

      if (finalRemoveClasses.length) document.documentElement.classList.remove(...finalRemoveClasses);
      if (finalAddClasses.length) document.documentElement.classList.add(...finalAddClasses);
    },
    updateScrolldirection() {
      const existingClasses = Array.from(document.documentElement.classList);
      const addClasses = [].concat(this.page.scrollDirection || []);
      const removeClasses = ['scrolling-down', 'scrolling-up'];

      const finalRemoveClasses = removeClasses.filter(c => !addClasses.includes(c) && existingClasses.includes(c));
      const finalAddClasses = addClasses.filter(c => !existingClasses.includes(c));

      if (finalRemoveClasses.length) document.documentElement.classList.remove(...finalRemoveClasses);
      if (finalAddClasses.length) document.documentElement.classList.add(...finalAddClasses);

      this.page.scrollingDown = document.documentElement.classList.contains('scrolling-down');
      this.page.scrollingUp = document.documentElement.classList.contains('scrolling-up');
    },
    updateHeaderHeight() {
      const header = document.querySelector('#app > header');
      const bounds = header ? header.getBoundingClientRect() : {};
      this.header.height = bounds.height || 0;
      this.header.inViewportHeight = this.header.height + (bounds.top || 0);
    }
  },
  created() {
    const debouncedUpdate = debounce(this.updateData, 150);

    window.addEventListener('load', this.onLoad);
    window.addEventListener('resize', debouncedUpdate);
    window.addEventListener('scroll', this.updateOnScroll);
  },
  beforeDestroy() {
    const debouncedUpdate = debounce(this.updateData, 150);

    window.removeEventListener('load', this.onLoad);
    window.removeEventListener('resize', debouncedUpdate);
    window.removeEventListener('scroll', this.updateOnScroll);
  }
};

</script>
