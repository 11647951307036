import Vue from 'vue'
import Vuex from 'vuex'

// custom stores ----------------------------

// base
import configuration from './modules/configuration'
import gui from './modules/gui'
import gtm from './modules/gtm'
import profile from './modules/profile'
import validators from './modules/validators'

// content
import globalsearch from './modules/globalsearch'
import shoppingcart from './modules/shoppingcart'
import wishlist from './modules/wishlist'
import checkout from './modules/checkout'
import orderaccessor from './modules/orderaccessor'
import search from './modules/search'

// entity stores ----------------------------

import locations from './modules/locations'
import locationaddresses from './modules/location-addresses'
import userlocations from './modules/user-locations'
import users from './modules/users'
import useraddresses from './modules/user-addresses'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    configuration,
    gui,
    gtm,
    profile,
    validators,

    globalsearch,
    shoppingcart,
    wishlist,
    checkout,
    orderaccessor,
    search,

    locations,
    locationaddresses,
    userlocations,
    users,
    useraddresses
  }
})
