import { COMPONENT_SEARCH_TYPES } from '@/constants'

import http from '@/$plugins/http'
import Vue from 'vue'

import base from './base'
import IS from './base/is'

import { getSearchParameters, resetUrlParametersReplaceState } from '@/assets/js/helper/url'

import {
  activeFiltersMapper,
  filterControlResetter,
  filterIdGenerator,
  filterMapper,
  filterParameters,
  initialParameterSetter,
  resetInitialFilter,
  resetInitialFilters,
  resultMapper,
  searchParameters,
  sortingIdGenerator,
  sortingMapper,
  urlParameterKeySetter,
  urlParameterSetter
} from '@/assets/js/helper/search'

const SEARCH_TYPE_KEYS = Object.keys(COMPONENT_SEARCH_TYPES)
const SEARCH_TYPE_DEFAULT_KEY = SEARCH_TYPE_KEYS.find((oKey) => COMPONENT_SEARCH_TYPES[oKey].isDefault) || SEARCH_TYPE_KEYS[0]

const VALID_VIEW_MODES = ['grid', 'stack']
const DEFAULT_VIEW_MODE = 'stack'
const INITIAL_VIEW_MODE = localStorage.getItem('searchViewMode') || DEFAULT_VIEW_MODE

export default {
  namespaced: true,
  state: base.createState(IS.state, {
    type: SEARCH_TYPE_DEFAULT_KEY,
    endpoint: COMPONENT_SEARCH_TYPES[SEARCH_TYPE_DEFAULT_KEY].endpoint,
    statistics: {
      page: {
        number: COMPONENT_SEARCH_TYPES[SEARCH_TYPE_DEFAULT_KEY].paging.page,
        size: COMPONENT_SEARCH_TYPES[SEARCH_TYPE_DEFAULT_KEY].paging.pageSize
      },
      total: {
        items: 0,
        pages: 0
      }
    },
    initialPage: 1,
    query: null,
    filters: [],
    sortings: [],
    results: [],
    renderCategories: false,
    viewMode: VALID_VIEW_MODES.includes(INITIAL_VIEW_MODE) ? INITIAL_VIEW_MODE : DEFAULT_VIEW_MODE
  }),
  getters: base.createGetters(IS.getters, {
    statistics: (state) => state.statistics,
    initialPage: (state) => state.initialPage,
    query: (state) => state.query,
    filters: (state) => state.filters,
    getActiveFilters: (state) => activeFiltersMapper(state.filters),
    sortings: (state) => state.sortings,
    getActiveSorting: (state) => state.sortings.find((s) => s.isActive),
    get: (state) => state.results,
    getResult: (state) => (resultId) => state.results.find((r) => r.id === resultId),
    getLoadedCount: (state) => state.results.length,
    getCanLoadMore: (state) => state.statistics.total.items > state.results.length,
    getRenderCategories: (state) => state.renderCategories,
    getViewMode: (state) => state.viewMode
  }),
  mutations: base.createMutations(IS.mutations, {
    setSearchType(state, searchType = null) {
      if (SEARCH_TYPE_KEYS.includes(searchType)) {
        state.type = searchType
        state.endpoint = COMPONENT_SEARCH_TYPES[searchType].endpoint
        state.statistics.page.number = COMPONENT_SEARCH_TYPES[searchType].paging.page
        state.statistics.page.size = COMPONENT_SEARCH_TYPES[searchType].paging.pageSize
      }
    },
    setStatistics(state, statistics = {}) {
      state.statistics = {
        page: {
          number: statistics.pageNumber,
          size: statistics.pageSize
        },
        total: {
          items: statistics.totalItems,
          pages: statistics.totalPages
        }
      }
    },
    setFilters(state, filters = []) {
      filters
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .forEach((filter) => {
          const FILTER_ID = filterIdGenerator(filter)
          const updateIndex = state.filters.findIndex((f) => f.id === FILTER_ID)
          const newIndex = state.filters.length
          const existingFilter = state.filters[updateIndex]

          Vue.set(state.filters, updateIndex >= 0 ? updateIndex : newIndex, filterMapper(filter, existingFilter))
        })
    },
    setSortings(state, sortings = []) {
      sortings.forEach((sorting) => {
        const SORTING_ID = sortingIdGenerator(sorting)
        const updateIndex = state.sortings.findIndex((s) => s.id === SORTING_ID)
        const newIndex = state.sortings.length
        const existingSorting = state.sortings[updateIndex] || {}

        Vue.set(state.sortings, updateIndex >= 0 ? updateIndex : newIndex, sortingMapper(sorting, existingSorting))
      })

      state.sortings.sort((a, b) => a.sortOrder - b.sortOrder)
    },
    set(state, results = []) {
      state.results = resultMapper(results, state.type)
    },
    append(state, results = []) {
      state.results = state.results.concat(resultMapper(results, state.type))
    },
    setRenderCategories(state, renderCategories = false) {
      state.renderCategories = renderCategories
    },
    setInitialPage(state, initialPage) {
      state.initialPage = initialPage
    },
    setViewMode(state, viewMode) {
      state.viewMode = viewMode
    }
  }),
  actions: base.createActions(IS.actions, {
    async init({ state, getters, commit, dispatch }, { searchType = SEARCH_TYPE_DEFAULT_KEY, filters = [], extendedHiddenFilters = [] }) {
      commit('setSearchType', searchType)
      initialParameterSetter(filters, extendedHiddenFilters)
      await dispatch('searchByFilters')

      const searchParams = new URLSearchParams(window.location.search)
      if (searchParams.get('page')) {
        const targetPage = parseInt(searchParams.get('page'))

        if (state.statistics.total.pages <= targetPage) {
          const relElement = document.querySelector('link[rel=next]')
          if (relElement) {
            relElement.remove()
          }
        }

        commit('setInitialPage', targetPage)
        if (targetPage > 1) {
          while (targetPage > state.statistics.page.number && targetPage <= state.statistics.total.pages) {
            await dispatch('getNextSearchPage')
          }
        }
      }
    },
    searchByFilters({ state, getters, commit, dispatch, rootGetters }, options = { append: false }) {
      const isKey = `${state.type}/searchByFilters`
      const append = options.append !== undefined ? options.append : false

      const SEARCH_PARAMETERS = searchParameters({
        filters: state.filters,
        sortings: state.sortings,
        statistics: state.statistics
      })

      commit('setLoading', { key: isKey, loading: true, initial: true })

      const urlParameters = getSearchParameters()
      let querySearch = urlParameters.find((p) => p.key === 'query')?.value
      if (querySearch && sessionStorage.getItem(`searchQuery_${querySearch}`)) {
        querySearch = sessionStorage.getItem(`searchQuery_${querySearch}`)
      }
      state.query = querySearch ?? null
      if (querySearch) {
        commit('setRenderCategories', true)
      } else {
        commit('setRenderCategories', false)
      }

      return new Promise((resolve, reject) => {
        http({
          method: 'post',
          url: `search/api/${state.endpoint}/SearchByFilters`,
          data: {
            language: rootGetters['gui/language:get'],
            query: state.query,
            filters: SEARCH_PARAMETERS.filters,
            sort: SEARCH_PARAMETERS.sortings,
            page: SEARCH_PARAMETERS.page.number,
            pageSize: SEARCH_PARAMETERS.page.size
          }
        })
          .then((response) => {
            response.data.filters = response.data.filters.filter((x) => !SEARCH_PARAMETERS.extendedHiddenFilters.includes(x.key))

            urlParameterKeySetter(response.data.filters)

            commit('setStatistics', response.data)
            commit('setFilters', response.data.filters)

            // Immediately update url to prevent flickering
            const urlParameters = [...state.filters, { key: 'page', values: state.statistics.page.number > 1 ? [state.statistics.page.number] : [] }]

            urlParameterSetter(filterParameters(urlParameters))
            const queryFromUrlParameter = getSearchParameters()
            const newQuerySearch = queryFromUrlParameter.find((p) => p.key === 'query')?.value
            if (newQuerySearch && newQuerySearch !== querySearch) {
              sessionStorage.setItem(`searchQuery_${newQuerySearch}`, querySearch)
            }

            commit('setSortings', response.data.sorts)
            commit(append ? 'append' : 'set', response.data.result)
            dispatch(
              'gtm/productImpressions',
              {
                list: COMPONENT_SEARCH_TYPES[state.type].gtm.list,
                products: response.data.result,
                options: {
                  startPosition: Math.max(0, state.results.length - response.data.result.length)
                }
              },
              { root: true }
            )

            resolve(getters.get)
          })
          .catch(reject)
          .finally(() => {
            commit('setLoading', { key: isKey, loading: false })
          })
      })
    },
    setFilters({ state, getters, commit, dispatch }, options = { action: undefined }) {
      return new Promise((resolve) => {
        dispatch('resetPage', { action: false })
        options.action !== false ? dispatch(options.action !== undefined ? options.action : 'searchByFilters').finally(resolve) : resolve()
      })
    },
    setActiveSorting({ state, getters, commit, dispatch }, options = { sortingId: null, action: undefined }) {
      return new Promise((resolve) => {
        const oldActiveSorting = state.sortings.find((s) => s.isActive) || {}
        const newActiveSorting = state.sortings.find((s) => s.id === options.sortingId) || {}

        if (newActiveSorting.id && newActiveSorting !== oldActiveSorting) {
          oldActiveSorting.isActive = false
          newActiveSorting.isActive = true
          dispatch('resetPage', { action: false })
        }

        options.action !== false ? dispatch(options.action === undefined ? 'searchByFilters' : options.action).finally(resolve) : resolve()
      })
    },
    getNextSearchPage({ state, getters, commit, dispatch }, options = { action: undefined }) {
      return new Promise((resolve) => {
        state.statistics.page.number += 1
        state.statistics.page.size = COMPONENT_SEARCH_TYPES[state.type].paging.nextPageSize

        options.action !== false
          ? dispatch(options.action !== undefined ? options.action : 'searchByFilters', { append: true }).finally(resolve)
          : resolve()
      })
    },
    resetFilters({ state, getters, commit, dispatch }, options = { action: undefined }) {
      return new Promise((resolve) => {
        state.filters.map((filter) => Object.assign(filter, { controls: filterControlResetter(filter) }))
        resetInitialFilters()
        dispatch('resetPage', { action: false })
        dispatch('resetQuery', { action: false })

        options.action !== false ? dispatch(options.action !== undefined ? options.action : 'searchByFilters').finally(resolve) : resolve()
      })
    },
    resetFilter({ state, getters, commit, dispatch }, options = { filterId: null, action: undefined }) {
      return new Promise((resolve) => {
        const resetFilter = state.filters.find((filter) => filter.id === options.filterId)

        if (resetFilter) {
          resetFilter.controls = filterControlResetter(resetFilter)
          resetInitialFilter(resetFilter)

          dispatch('resetPage', { action: false })
          dispatch('resetQuery', { action: false })

          options.action !== false ? dispatch(options.action !== undefined ? options.action : 'searchByFilters').finally(resolve) : resolve()
        } else {
          resolve()
        }
      })
    },
    resetSortings({ state, getters, commit, dispatch }, options = { action: undefined }) {
      return new Promise((resolve) => {
        state.sortings.map((sorting) => Object.assign(sorting, { isActive: sorting.isDefault }))
        dispatch('resetPage', { action: false })

        options.action !== false ? dispatch(options.action !== undefined ? options.action : 'searchByFilters').finally(resolve) : resolve()
      })
    },
    resetPage({ state, getters, commit, dispatch }, options = { action: undefined }) {
      return new Promise((resolve) => {
        state.statistics.page.number = COMPONENT_SEARCH_TYPES[state.type].paging.page
        state.statistics.page.size = COMPONENT_SEARCH_TYPES[state.type].paging.pageSize

        options.action !== false ? dispatch(options.action !== undefined ? options.action : 'searchByFilters').finally(resolve) : resolve()
      })
    },
    resetQuery({ state, getters, commit, dispatch }, options = { action: undefined }) {
      return new Promise((resolve) => {
        var filtersToReset = [...state.filters.map((f) => f.key), 'page']
        resetUrlParametersReplaceState(filtersToReset)

        options.action !== false ? dispatch(options.action !== undefined ? options.action : 'searchByFilters').finally(resolve) : resolve()
      })
    },
    setViewMode({ state, getters, commit, dispatch }, options = { viewMode: 'grid' }) {
      return new Promise((resolve) => {
        commit('setViewMode', options.viewMode)
        localStorage.setItem('searchViewMode', options.viewMode)
        resolve()
      })
    }
  })
}
