const gtm = window.dataLayer ?? []

export default {
  namespaced: true,
  state: {},
  getters: {
    isAvailable: state => window.google_tag_manager !== undefined && window.dataLayer !== undefined
  },
  mutations: {},
  actions: {
    pageView ({ state, getters, commit, dispatch }, { type, callback = undefined }) {
      gtm.push({
        event: 'pageType',
        ecommerce: {
          impressions: [type]
        },
        eventCallback: callback
      })
    },
    productImpressions ({ state, getters, commit, dispatch }, { list, products = [], currency = 'CHF', callback = undefined, options = {} }) {
      gtm.push({
        event: 'productImpression',
        ecommerce: {
          currencyCode: currency,
          impressions: mapProducts({ list, products }, { mapPosition: true, startPosition: options.startPosition ?? 0 })
        },
        eventCallback: callback
      })
    },
    productClick ({ state, getters, commit, dispatch }, { list, product = {}, currency = 'CHF', callback = undefined }) {
      gtm.push({
        event: 'productClick',
        ecommerce: {
          currencyCode: currency,
          click: {
            actionField: { list },
            products: mapProducts({ products: [product] }, {})
          }
        },
        eventCallback: callback
      })
    },
    promotionImpressions ({ state, getters, commit, dispatch }, { promotions = [], currency = 'CHF', callback = undefined }) {
      gtm.push({
        event: 'promotionImpression',
        ecommerce: {
          currencyCode: currency,
          promoView: {
            promotions: mapPromotions({ promotions })
          }
        },
        eventCallback: callback
      })
    },
    promotionClick ({ state, getters, commit, dispatch }, { promotion = {}, currency = 'CHF', callback = undefined }) {
      gtm.push({
        event: 'promotionClick',
        ecommerce: {
          currencyCode: currency,
          promoClick: {
            promotions: mapPromotions({ promotions: [promotion] })
          }
        },
        eventCallback: callback
      })
    },
    productDetails ({ state, getters, commit, dispatch }, { list, product = {}, currency = 'CHF', callback = undefined }) {
      gtm.push({
        event: 'productDetails',
        ecommerce: {
          currencyCode: currency,
          detail: {
            actionField: { list },
            products: mapProducts({ products: [product] }, {})
          }
        },
        eventCallback: callback
      })
    },
    addToCart ({ state, getters, commit, dispatch }, { list, product = {}, currency = 'CHF', callback = undefined }) {
      gtm.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: currency,
          add: {
            actionField: { list },
            products: mapProducts({ products: [product] }, {})
          }
        },
        eventCallback: callback
      })
    },
    addToWishlist ({ state, getters, commit, dispatch }, { list, product = {}, currency = 'CHF', callback = undefined }) {
      gtm.push({ ecommerce: null })
      gtm.push({
        event: 'add_to_wishlist',
        ecommerce: {
          currencyCode: currency,
          items: mapGa4Products({ list, products: [product] }, {})
        },
        eventCallback: callback
      })
    },
    removeFromCart ({ state, getters, commit, dispatch }, { list, product = {}, currency = 'CHF', callback = undefined }) {
      gtm.push({
        event: 'removeFromCart',
        ecommerce: {
          currencyCode: currency,
          remove: {
            actionField: { list },
            products: mapProducts({ products: [product] }, {})
          }
        },
        eventCallback: callback
      })
    },
    removeFromWishlist ({ state, getters, commit, dispatch }, { list, product = {}, currency = 'CHF', callback = undefined }) {
      gtm.push({ ecommerce: null })
      gtm.push({
        event: 'remove_from_wishlist',
        ecommerce: {
          currencyCode: currency,
          items: mapGa4Products({ list, products: [product] }, {})
        },
        eventCallback: callback
      })
    },
    checkout ({ state, getters, commit, dispatch }, { step, option, list, products = [], currency = 'CHF', callback = undefined }) {
      gtm.push({
        event: 'checkout',
        ecommerce: {
          currencyCode: currency,
          checkout: {
            actionField: clean({ step, option }),
            products: mapProducts({ list, products }, {})
          }
        },
        eventCallback: callback
      })
    },
    purchase ({ state, getters, commit, dispatch }, { id, coupon, revenue, tax, shipping, list, products = [], currency = 'CHF', callback = undefined }) {
      gtm.push({
        event: 'ecommerce_thankyou',
        ecommerce: {
          currencyCode: currency,
          purchase: {
            actionField: { id: id, coupon, revenue, tax, shipping },
            products: mapProducts({ list, products }, {})
          }
        },
        eventCallback: callback
      })
    }
  }
}

function mapProducts ({ list, products = [] }, { mapPosition = false, startPosition = 0 }) {
  return products
    .map((p, pIndex) => {
      const priceinfo = p.priceInfo ?? {}
      const characteristics = p.characteristics ?? []

      return clean({
        list: list ?? undefined,
        id: p.id,
        name: p.name,
        brand: p.model,
        price: priceinfo.price,
        category: (p.categories ?? []).join('/'),
        variant: characteristics.map(c => `${c.name}: ${c.value}`).join(', '),
        quantity: p.quantity,
        position: mapPosition ? startPosition + pIndex + 1 : undefined
      })
    })
}

function mapGa4Products ({ list, products = [] }, { mapPosition = false, startPosition = 0 }) {
  return products
    .map((p, pIndex) => {
      const priceinfo = p.priceInfo ?? {}
      const characteristics = p.characteristics ?? []
      const categories = [...p.categories ?? []].reverse()

      return clean({
        item_id: p.id,
        item_name: p.name ?? p.model,
        currency: 'CHF',
        item_brand: p.model,
        item_category: categories[0] ?? '',
        item_category2: categories[1] ?? '',
        item_category3: categories[2] ?? '',
        item_category4: categories[3] ?? '',
        item_category5: categories[4] ?? '',
        item_list_name: list ?? undefined,
        item_variant: characteristics.map(c => `${c.name}: ${c.value}`).join(', '),
        price: priceinfo.price,
        quantity: p.quantity
      })
    })
}

function mapPromotions ({ promotions = [] }) {
  return promotions
    .map((p, pIndex) => {
      return clean({
        id: p.id,
        name: p.name,
        creative: p.creative ?? undefined,
        position: p.position
      })
    })
}

function clean (object = {}) {
  Object.keys(object)
    .forEach(pKey => {
      if (object[pKey] === undefined) delete object[pKey]
    })

  return object
}
