import merge from 'lodash.merge'

export function flatten(object = {}, defaultValue) {
  const result = {}
  flattenObject(object, '', result, defaultValue)
  return result
}

function flattenObject(obj, parentKey, res, defaultValue) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const propName = key === defaultValue ? parentKey : (parentKey ? parentKey + '.' : '') + key

      if (Array.isArray(obj[key])) {
        for (let i = 0; i < obj[key].length; i++) {
          flattenObject(obj[key][i], propName + '[' + i + ']', res, defaultValue)
        }
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        flattenObject(obj[key], propName, res, defaultValue)
      } else {
        res[propName] = obj[key]
      }
    }
  }
}

export function unflatten(object = {}, splitter = '.') {
  const uObj = {}

  for (const flatKey in object) {
    if (Object.prototype.hasOwnProperty.call(object, flatKey)) { // Check if the property is directly on the object
      let current = uObj
      const keys = flatKey.split(splitter)
      const lastKeyIndex = keys.length - 1

      for (let i = 0; i < lastKeyIndex; ++i) {
        const key = keys[i]
        if (!(key in current)) current[key] = {} // Create a new object if the key doesn't exist
        current = current[key] // Drill down to the next level
      }

      current[keys[lastKeyIndex]] = object[flatKey] // Set the final value
    }
  }

  return uObj
}

export function deepKeys (object = {}, exclude) {
  let dKeys = []

  for (const key in object) {
    if (key !== exclude) {
      dKeys.push(key)
      if (typeof object[key] === 'object') dKeys = dKeys.concat(deepKeys(object[key], exclude).map(sKey => `${key}.${sKey}`))
    }
  }

  return dKeys
}

export function propertyByStringPath (object = {}, stringPath = '', returnUndefined = false) {
  const propertyIfUndefined = !returnUndefined ? stringPath : undefined
  let property = Object.assign({}, object)

  stringPath
    .split('.')
    .forEach(pKey => {
      property = property[pKey] || {}
    })

  return typeof property === 'string' ? property || propertyIfUndefined : propertyIfUndefined
}

export function copy (data) {
  const mergeBase = Array.isArray(data) ? [] : {}
  return merge(mergeBase, data)
}

export default {
  flatten,
  unflatten,
  deepKeys,
  propertyByStringPath,
  copy
}
