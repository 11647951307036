const CONFIGURATION = window.servicePlatformConfiguration || {}

export default {
  namespaced: true,
  state: {
    baseUrl: CONFIGURATION.baseUrl || process.env.BASE_URL,
    isOnline: CONFIGURATION.isOnline,
    token: CONFIGURATION.accessToken
  },
  getters: {
    get: state => state,
    getBaseUrl: state => state.baseUrl,
    getToken: state => state.token,
    getIsAuthenticated: state => state.token !== undefined && state.token !== null && state.token !== ''
  },
  mutations: {},
  actions: {}
}
