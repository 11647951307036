import Vue from 'vue'

import bootstrap from '@/$plugins/bootstrap'
import http from '@/$plugins/http'
import i18n from '@/$plugins/i18n'
import store from '@/$plugins/store'
import vuelidate, { validators } from '@/$plugins/vuelidate'

import SCSS_VARIABLES from '@/assets/js/modules/scss-variables'

import MixinScrollbarSuppressor from '@/components/mixins/ScrollbarSuppressor'
import MixinViewport from '@/components/mixins/Viewport'

import 'lazysizes'

import '@/assets/css/app.scss'
import '@/assets/js/app.js'

// For more informations visit: https://vuejs.org/v2/api/
Vue.config.productionTip = false
Vue.config.performance = process.env.NODE_ENV === 'development'
Vue.config.devtools = process.env.NODE_ENV === 'development'

Vue.prototype.window = window
Vue.prototype.document = document

document.documentElement.classList.add((process.env.ENV_NAME || '').toLowerCase())

// eslint-disable-next-line no-new
const vueInstance = new Vue({
  store,
  http,
  i18n,
  vuelidate,
  bootstrap,
  el: '#app',
  mixins: [MixinViewport, MixinScrollbarSuppressor],
  data: {
    env: {
      name: process.env.ENV_NAME,
      isDevelopment: process.env.NODE_ENV === 'development',
      isProduction: process.env.NODE_ENV === 'production',
      publicPath: process.env.BASE_URL,
      isTouch: 'ontouchstart' in window || window.navigator.maxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0
    },
    scss: SCSS_VARIABLES,
    validators
  },
  methods: {
    isNullOrEmpty(value) {
      return value === null || value === undefined || value === '' || value === []
    },
    isBoolean(value) {
      return typeof value === 'boolean'
    }
  },
  mounted() {
    document.documentElement.classList.add(...['ready', this.env.isTouch ? 'is-touch' : null].filter((c) => c))
    document.dispatchEvent(new CustomEvent('app:ready'))
  },
  updated() {
    document.dispatchEvent(new CustomEvent('app:updated'))
  }
})

window.vueInstance = vueInstance
