export function guid () {
  let timestamp = new Date().getTime()

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, (charType) => {
      const char = (timestamp + Math.random() * 16) % 16 | 0
      timestamp = Math.floor(timestamp / 16)

      return (charType === 'x' ? char : (char & 0x3 | 0x8)).toString(16)
    })
}

export default {
  guid
}
