import Vue from 'vue'
import axios from 'axios'
import configuration from '@/$plugins/store/modules/configuration'

const http = axios.create({
  name: '$http',
  baseURL: configuration.getters.getBaseUrl(configuration.state),
  withCredentials: true
})

const axiosInstances = {
  authentication: http
}

Object.values(axiosInstances)
  .forEach(i => {
    Vue.prototype[i.defaults.name] = i
  })

export {
  http as default,
  axiosInstances as instances
}
