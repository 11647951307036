<script>
const scrollbarSupressor = {
  supressors: {},
  state: false,
  scrollTopBefore: 0
}

export default {
  created() {
    window.addEventListener('load', event => updateScrollbarSuppression.bind(this)(event))
    window.addEventListener('resize', event => updateScrollbarSuppression.bind(this)(event))
    this.$root.$on('scrollbar:toggle', payload => registerScrollbarSuppressor.bind(this)(payload))
  }
}

function registerScrollbarSuppressor({ key = 'default', state = false, from = null, till = null }) {
  scrollbarSupressor.supressors[key] = {
    key,
    state,
    from: from || 0,
    till: till || Infinity
  }

  updateScrollbarSuppression.bind(this)()
}

function updateScrollbarSuppression() {
  const newState = Object.values(scrollbarSupressor.supressors)
    .filter(s => s.from <= window.innerWidth && s.till >= window.innerWidth)
    .some(s => s.state === true)

  if (scrollbarSupressor.state !== newState) {
    if (!scrollbarSupressor.state && newState) {
      scrollbarSupressor.scrollTopBefore = this.$root.page.scrollTop
    }

    scrollbarSupressor.state = newState
    window.document.documentElement.classList[scrollbarSupressor.state ? 'add' : 'remove']('suppress-scrollbar')

    if (scrollbarSupressor.state) {
      window.document.body.style.top = `-${scrollbarSupressor.scrollTopBefore}px`
    } else {
      window.document.body.style.top = null
      window.scrollTo(0, scrollbarSupressor.scrollTopBefore)
      scrollbarSupressor.scrollTopBefore = 0
    }
  }
}
</script>

<style lang="scss">
.suppress-scrollbar {
  body {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
  }
}
</style>
