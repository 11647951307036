import Vue from 'vue'

export default {
  state: {
    is: {
      byInstance: {
        initial: { default: false },
        loading: { default: false },
        sending: { default: false }
      },
      overall: {
        initial: false,
        loading: false,
        sending: false
      }
    }
  },
  getters: {
    is: state => state.is.overall
  },
  mutations: {
    setInitial (state, { key = 'default', initial = false }) {
      Vue.set(state.is.byInstance, 'initial', Object.assign(state.is.byInstance.initial, { [key]: initial }))
      state.is.overall.initial = Object.values(state.is.byInstance.initial).includes(true)
    },
    setLoading (state, { key = 'default', loading = false, initial = null }) {
      Vue.set(state.is.byInstance, 'initial', Object.assign(state.is.byInstance.initial, { [key]: initial !== null ? initial : loading }))
      Vue.set(state.is.byInstance, 'loading', Object.assign(state.is.byInstance.loading, { [key]: loading }))
      state.is.overall.initial = Object.values(state.is.byInstance.initial).includes(true)
      state.is.overall.loading = Object.values(state.is.byInstance.loading).includes(true)
    },
    setSending (state, { key = 'default', sending = false }) {
      Vue.set(state.is.byInstance, 'sending', Object.assign(state.is.byInstance.sending, { [key]: sending }))
      state.is.overall.sending = Object.values(state.is.byInstance.sending).includes(true)
    }
  },
  actions: {}
}
