import { COMPONENT_SEARCH_FILTER_URL_PARAMETER_SEPARATOR } from '@/constants'

export function getUrlSlugs () {
  return window.location.pathname.split('/')
}

export function getSearchParameters (key = null) {
  const urlSearch = new URLSearchParams(window.location.search)

  const result = []
  urlSearch.forEach((value, key) => {
    result.push({ key: key, value: value })
  })

  if (result.map(p => p.key).includes(key)) {
    return result.find(p => p.key === key).value
  } else if (key !== null) {
    return null
  }

  return result
}

export function resetUrlParametersReplaceState (parameterNames = []) {
  if (parameterNames === undefined || parameterNames === null || parameterNames.length === 0) {
    return
  }

  const urlSearch = new URLSearchParams(window.location.search)

  parameterNames.map(p => urlSearch.delete(p))

  const FULL_URL = getFullUrl(urlSearch.toString())
  window.history.replaceState(null, null, FULL_URL || window.location.pathname)
}

export function setSearchParameters (parameters = { }) {
  const urlSearch = new URLSearchParams(window.location.search)
  Object.keys(parameters).map(paramName => {
    const value = parameters[paramName]
    if (value !== undefined && value !== null) {
      let valueString
      if (Array.isArray(value)) {
        valueString = value.join(COMPONENT_SEARCH_FILTER_URL_PARAMETER_SEPARATOR)
      } else {
        valueString = value.toString()
      }

      urlSearch.set(paramName, valueString)
    } else {
      urlSearch.delete(paramName)
    }
  })

  return urlSearch.toString()
}

export function getFullUrl (queryString = '') {
  let newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname

  if (queryString && queryString.length > 0) {
    newUrl += '?' + queryString
  }

  return newUrl
}
