export default {
  decimal: {
    style: 'decimal',
    useGrouping: true
  },
  currency: {
    style: 'currency',
    useGrouping: true
  },
  currencynumber: {
    style: 'decimal',
    useGrouping: true
  }
}
