import http from '@/$plugins/http'
import { validators as fallbackValidators } from '@/$plugins/vuelidate'
import { helpers } from 'vuelidate/lib/validators'

import base from './base'
import IS from './base/is'

export default {
  namespaced: true,
  state: base.createState(IS.state, {
    promises: {
      validation: null
    },
    validators: []
  }),
  getters: base.createGetters(IS.getters, {
    get: state => state.validators,
    getTyped: state => state.validators.reduce((validators, v) => Object.assign(validators, { [v.type]: v }), {})
  }),
  mutations: base.createMutations(IS.mutations, {
    set (state, { validators = [], language = '' }) {
      state.validators = validators
        .map(validator => {
          const type = validator.id.replace(/Validator$/, '')
          const typeDiscriminator = validator.typeDiscriminator
          const fallbackValidator = fallbackValidators[type]

          let isFallbackValidator = false
          let validation = function () {
            if (process.env.NODE_ENV === 'development') console.warn(`Could not find a validation for type "${type}"!`)
            return true
          }

          if (validator.typeDiscriminator === 'RegexValidator') {
            if (validator.settings.regex) {
              validation = helpers.regex(validator.id, new RegExp(validator.settings.regex))
            } else if (fallbackValidator) {
              isFallbackValidator = true
              validation = fallbackValidator
            }
          } else if (fallbackValidator) {
            isFallbackValidator = true
            validation = fallbackValidator
          }

          return Object.assign(validator, {
            id: validator.id,
            type,
            typeDiscriminator: typeDiscriminator,
            isFallbackValidator,
            validation: value => validation(value),
            translations: validator.translations,
            invalidFeedback: (validator.translations[language] || {}).errorMessage || ''
          })
        })
    }
  }),
  actions: base.createActions(IS.actions, {
    get ({ state, getters, commit, dispatch, rootGetters }) {
      commit('setLoading', { key: 'validators/get', loading: true, initial: state.validators.length < 1 })

      if (state.promises.validation) {
        // return existing, unresolved Promise (resolves, as soon as the existing Promise resolves)
        return state.promises.validation
      } else if (state.validators.length > 0) {
        // return new, resolved Promise (with existing data)
        return Promise
          .resolve(getters.get)
          .finally(() => {
            commit('setLoading', { key: 'validators/get', loading: false })
          })
      } else {
        // return new, unresolved Promise
        state.promises.validation = new Promise((resolve, reject) => {
          http({
            method: 'get',
            url: '/customer/api/Validators'
          })
            .then(response => {
              commit('set', { validators: response.data.result, language: rootGetters['gui/language:get'] })
              resolve(getters.get)
            })
            .catch(reject)
            .finally(() => {
              commit('setLoading', { key: 'validators/get', loading: false })
              state.promises.validation = null
            })
        })

        return state.promises.validation
      }
    }
  })
}
