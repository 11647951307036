// Utility functions and event handlers for the navigation

export const getHeaderInViewportHeight = () => {
  const header = document.querySelector('#app > header');
  const bounds = header ? header.getBoundingClientRect() : {};
  const height = bounds.height || 0;
  return height + (bounds.top || 0);
}

export const subMenuHandler = (subMenuItem, defaultContent, isEnter) => () => {
  const container = subMenuItem.closest('.mega-dropdown');
  const rightContent = subMenuItem.querySelector('.right-content');

  if (isEnter && rightContent) {
    container.style.minHeight = `${rightContent.offsetHeight + 30}px`;
    defaultContent.style.display = 'none';
  } else if (!isEnter) {
    container.style.removeProperty('min-height');
    defaultContent.style.removeProperty('display');
  }
};

export const subMenuCloseHandler = (e, mainItem) => {
  let target = e.target;

  while (target && !target.classList.contains('close-button') && target !== mainItem) {
    target = target.parentElement;
  }

  if (!target || target === mainItem) {
    return;
  }

  const dropdown = mainItem.querySelector('.mega-dropdown');
  dropdown.style.display = 'none';
  setTimeout(() => {
    dropdown.style.removeProperty('display');
  }, 1);
};

export const cloneContainerAndPrepareSildeInFromRight = (menuContainer) => {
  const clone = menuContainer.cloneNode(true);

  menuContainer.classList.add('is-right-out');
  menuContainer.parentElement.insertBefore(clone, menuContainer);

  return clone;
}

export const cloneContainerAndPrepareSildeInFromLeft = (menuContainer) => {
  const clone = menuContainer.cloneNode(true);

  menuContainer.classList.add('is-left-out');
  menuContainer.parentElement.insertBefore(clone, menuContainer);

  return clone;
}

export const slideInFromRightAndCleanup = (menuContainer, clone) => {
  menuContainer.classList.remove('is-right-out');
  menuContainer.classList.add('slide-in-right');
  clone.classList.add('slide-out-left');

  setTimeout(() => {
    clone.remove();
    menuContainer.classList.remove('slide-in-right');
  }, 300);
}

export const slideInFromLeftAndCleanup = (menuContainer, clone) => {
  menuContainer.classList.remove('is-left-out');
  menuContainer.classList.add('slide-in-left');
  clone.classList.add('slide-out-right');

  setTimeout(() => {
    clone.remove();
    menuContainer.classList.remove('slide-in-left');
  }, 300);
}

export const setActiveMobileLevel = (item, navigation, mainNavigation) => {
  const backCaretIcon = document.getElementById('BackCaretIcon');
  const backToMainLink = navigation.querySelector('.back-to-mainnav');
  navigation.querySelectorAll('.mobile-active, .mobile-active-path, .mobile-active-link, .mobile-back-link').forEach(activeItem => {
    activeItem.classList.remove('mobile-active', 'mobile-active-path', 'mobile-active-link', 'mobile-back-link');
  });

  navigation.querySelectorAll('.back-caret-icon, .temp-right-content').forEach(elementToCleanup => {
    elementToCleanup.remove();
  });

  if (!backToMainLink.classList.contains('d-none')) {
    backToMainLink.classList.add('d-none');
  }

  if (item === mainNavigation) {
    item.classList.add('mobile-active');
    return;
  }

  const parent = item.parentElement;
  let leftContent = parent.querySelector('.left-content');
  const rightContent = parent.querySelector('.right-content');

  if (!leftContent && !rightContent) {
    leftContent = parent.closest('.left-content');
  }

  if (leftContent) {
    leftContent.classList.add('mobile-active');
    item.classList.add('mobile-active-link');

    backToMainLink.classList.add('mobile-active-path');
    backToMainLink.classList.remove('d-none');
  } else if (rightContent) {
    rightContent.classList.add('mobile-active');
    item.classList.add('mobile-active-link');
  } else {
    mainNavigation.classList.add('mobile-active');
  }

  const mainItem = parent.closest('.main-item');
  const menuItem = parent.closest('.menu-item');

  if (mainItem) {
    mainItem.classList.add('mobile-active-path');
  }
  if (menuItem && rightContent) {
    menuItem.classList.add('mobile-active-path');
  }

  const ignoredRightContent = parent.querySelector('.mega-dropdown > .left-content > .menu-items > .menu-item.ignore-mobile > .right-content');
  if (ignoredRightContent) {
    const targetElement = parent.querySelector('.mega-dropdown')
    const elementToCopy = ignoredRightContent.cloneNode(true);
    elementToCopy.classList.remove('right-content');
    elementToCopy.classList.add('d-lg-none');
    elementToCopy.classList.add('temp-right-content');
    targetElement.appendChild(elementToCopy);
  }

  // Handle back caret
  if (menuItem && mainItem && rightContent) {
    const backCaret = backCaretIcon?.cloneNode(true);
    if (backCaret) {
      backCaret.classList.remove('d-none');
      backCaret.classList.add('back-caret-icon');
    }

    mainItem.classList.add('mobile-back-link');
    const mainItemLink = mainItem.querySelector('.main-item-link');
    if (mainItemLink && backCaret) {
      mainItemLink.insertBefore(backCaret, mainItemLink.firstChild);
    }
  }
};
