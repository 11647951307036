import { name as packageName } from '@/../package.json'

import { validators } from '@/$plugins/vuelidate'

export const STOREMODULE_ENDPOINT_MAP = {
  // changetracking: 'ChangeTracking',
  // services: 'SubscriptionsAndServices',
  locations: 'Locations',
  locationaddresses: 'LocationAddress',
  users: 'Users',
  useraddresses: 'UserAddress',
  // userprofessions: 'Professions',
  userlogins: 'UserLogins',
  // userservices: 'UserSubscriptionsAndServices',
  // usereducations: 'EasyLearnProfile',
  userlocations: 'UserLocations'
}

export const LOCALE_STORAGE_KEYS = {
  loginstate: `${packageName}:LoginState`,
  shoppingcartid: `${packageName}:ShoppingCartId`,
  shoppingcartcount: `${packageName}:ShoppingCartCount`,
  shoppingcartitemids: `${packageName}:ShoppingCartItemIds`,
  wishlistid: `${packageName}:WishlistId`,
  wishlistcount: `${packageName}:WishlistCount`,
  wishlistitemids: `${packageName}:WishlistItemIds`,
  headerbanner: `${packageName}:HeaderBanner:{bannername}:Show`,
  cookiebanner: `${packageName}:CookieBanner:Show`,
  productconfiguratoreditor: `${packageName}:ProductConfigurator:EditorId`
}

export const USER_PROFILE_BASE_MODEL = {
  clientId: null,
  userId: null,
  userName: null,
  userExternalId: null,
  email: null,
  roles: [],
  approvalStatus: null
}

export const USER_ROLES = {
  user: 'User',
  customer: 'Customer',
  reseller: 'Reseller',
  store: 'StoreClerk',
  admin: 'Administrator',
  super: 'SuperAdministrator'
}

// implemented in Store-Module profile
// if the asked property (e.g. "doSomething") is defined in one or more UserRoles (e.g. "Customer" and "SuperAdministrator"), the last one is used
// if the asked property is not defined in any of the UserRoles, the return value will be true
// if you need a property to be false by default, add it to the first role (e.g. doSomething: false) and reset it only for allowed roles
// example of useage (return value = Boolean):
// this.$store.getters['profile/can']('{propertyName}')
export const USER_ROLE_CAN_RULES = {
  User: {},
  Customer: {},
  Reseller: {
    seeUserProfileAddresses: false
  },
  StoreClerk: {},
  Administrator: {},
  SuperAdministrator: {}
}

export const USER_ADDRESS_TYPES = {
  default: {
    type: 'Default',
    checkoutproperties: {
      key: 'newUserAddress'
    }
  },
  delivery: {
    type: 'DeliveryAddress',
    checkoutproperties: {
      key: 'newDeliveryAddress',
      useBaseKeyBoolean: 'deliveryAddressIsNewUserAddress',
      inheritKey: 'newUserAddress'
    }
  },
  invoice: {
    type: 'InvoiceAddress',
    checkoutproperties: {
      key: 'newInvoiceAddress',
      useBaseKeyBoolean: 'invoiceAddressIsDeliveryAddress',
      inheritKey: 'newDeliveryAddress'
    }
  }
}

export const PRODUCT_ASSEMBLYTYPES = {
  assembled: {
    type: 'Assembled',
    associatedPrice: 'price'
  },
  unassembled: {
    isDefault: true,
    type: 'Unassembled',
    associatedPrice: 'priceUnassembled'
  }
}

export const PRODUCT_ASSEMBLYTYPE_DEFAULT = (Object.values(PRODUCT_ASSEMBLYTYPES).find((t) => t.isDefault) || Object.values(PRODUCT_ASSEMBLYTYPES)[0])
  .type

export const ORDER_STATES = {
  open: 'Open',
  executed: 'Executed',
  ordered: 'Ordered',
  processed: 'Processed',
  closed: 'Closed',
  abandoned: 'Abandoned'
}

export const GLOBALSEARCH_RESULTTYPES = {
  categories: {
    maxResults: 6,
    row: 'content',
    urlType: 'product'
  },
  websiteContents: {
    maxResults: 3,
    row: 'content',
    urlType: null
  },
  products: {
    maxResults: 6,
    row: 'product',
    urlType: 'product'
  }
}

export const INPUT_BUTTON_SIZE_MAP = {
  xs: 'sm',
  sm: 'sm',
  md: 'sm',
  lg: 'md',
  xl: 'md'
}

export const DEFAULT_FILEPOND_URLS = {
  upload: '/customer/api/Files/create',
  download: '/customer/api/Files/',
  delete: '/customer/api/Files/'
}

export const CHANGEREQUEST_STATES_MAP = {
  open: 'Open',
  replaced: 'Replaced',
  approved: 'Approved',
  declined: 'Declined',
  closed: 'Closed'
}

export const PAGINATION_INITIAL_VALUES = {
  page: 1,
  pageSize: 24
}

export const COMPONENT_REGISTRATION_FORM_IDS = {
  user: 'Registration_Form_User',
  userAddresses: 'Registration_Form_UserAddresses',
  login: 'Registration_Form_Login',
  termsAndPolicies: 'Registration_Form_TermsAndPolicies'
}

export const COMPONENT_REGISTRATION_FORMDATA_DEFAULT = {
  user: {},
  userAddresses: [],
  login: {},
  termsAndPolicies: {}
}

export const COMPONENT_REGISTRATION_DEFAULT_TO_CHECKOUT_MAP = {
  user: 'newUser',
  userAddresses: 'newUserAddress',
  login: 'newLogin',
  'termsAndPolicies.termsOfUse': 'termsOfUse',
  'termsAndPolicies.policiyAndSecurity': 'policiyAndSecurity'
}

export const COMPONENT_REGISTRATION_HIDE_CONTROL_BY_NAME = {
  useraddresses: ['firstname', 'lastname', 'company']
}

export const COMPONENT_REGISTRATION_FORMDATA_VALIDATIONS = {
  user: (data) => Object.keys(data).length > 0,
  userAddresses: (data) => data.length > 0,
  login: {},
  termsAndPolicies: {}
}

export const COMPONENT_COLUMNS_MAX_COUNT = 3

export const COMPONENT_HEADERSHOPPINGCART_POPOVER_HIDE_TIMEOUT = 5000
export const COMPONENT_HEADERWISHLIST_POPOVER_HIDE_TIMEOUT = COMPONENT_HEADERSHOPPINGCART_POPOVER_HIDE_TIMEOUT

/*
  Docs:

  name: 'store-module-name'                 (required)
  isDefault: Boolean                        (optional, default = false, set to true only on one of the modules)
  isPreloaded: Boolean                      (optional, default = false, 'preloadedBasket' property must be set from outside of basket-component)
  showActionButtons: Boolean                (optional, default = true)
  showSummary: Boolean                      (optional, default = true)
  showAlsoBought: Boolean                   (optional, default = false)
  showItemQuantity: Boolean                 (optional, default = true)
  showItemTotalprice: Boolean               (optional, default = true)
  showItemDeliveryState: Boolean            (optional, default = true)
  showItemQuantityButton: Boolean           (optional, default = true)
  showItemServiceButtons: Boolean           (optional, default = true)
  showItemActionButtons: Boolean            (optional, default = true)
  getters: {
    get: 'getter-name'                      (required if isPreloaded == false)
  }
  actions: {
    get: 'get-action-name'                  (required if isPreloaded == false)
    delete: 'delete-action-name'            (required if showActions == true)
    addItem: 'additem-action-name'          (required if showServiceButtons == true)
    updateItem: 'updateitem-action-name'    (required if showQuantityButton == true)
    deleteItem: 'deleteitem-action-name'    (required if showActions == true)
  }

*/
export const COMPONENT_BASKET_MODULES = {
  // this is the base-model to define default states
  base: {
    isDefault: false,
    isPreloaded: false,
    switchOrderOfferTitle: false,
    showActionButtons: true,
    showSummary: true,
    showAlsoBought: false,
    showItemQuantity: true,
    showItemTotalprice: true,
    showItemDeliveryState: true,
    showItemQuantityButton: true,
    showItemServiceButtons: true,
    showItemActionButtons: true,
    itemDiscountWriteable: true,
    showStillRequiredProducts: false,
    getters: {},
    actions: {},
    gtm: {}
  },
  // these are the modules used at different places
  shoppingcart: {
    name: 'shoppingcart',
    isDefault: true,
    showAlsoBought: true,
    showItemServiceButtons: false,
    showStillRequiredProducts: true,
    getters: {
      basket: 'get'
    },
    actions: {
      get: 'get',
      delete: 'delete',
      addItem: 'addItem',
      updateItem: 'updateItem',
      deleteItem: 'deleteItem'
    },
    gtm: {
      step: 1,
      list: 'Category'
    }
  },
  wishlist: {
    name: 'wishlist',
    showSummary: false,
    showAlsoBought: false,
    showItemQuantity: false,
    showItemTotalprice: false,
    showItemDeliveryState: true,
    showItemQuantityButton: false,
    itemDiscountWriteable: false,
    getters: {
      basket: 'get'
    },
    actions: {
      get: 'get',
      delete: 'delete',
      addItem: 'addItem',
      updateItem: 'updateItem',
      deleteItem: 'deleteItem'
    },
    gtm: {
      list: 'Wishlist'
    }
  },
  checkoutregistration: {
    name: 'shoppingcart',
    showActionButtons: false,
    showSummary: false,
    showAlsoBought: false,
    showItemQuantity: false,
    showItemTotalprice: false,
    showItemDeliveryState: false,
    showItemQuantityButton: false,
    showItemServiceButtons: false,
    showItemActionButtons: false,
    getters: {
      basket: 'get'
    },
    actions: {
      get: 'get'
    },
    gtm: {
      step: 2,
      list: 'Category',
      initOnly: true
    }
  },
  checkoutoverview: {
    name: 'checkout',
    showAlsoBought: false,
    showActionButtons: false,
    showItemQuantityButton: false,
    showItemServiceButtons: false,
    showItemActionButtons: false,
    itemDiscountWriteable: false,
    getters: {
      basket: 'getBasket'
    },
    actions: {
      get: 'initialize'
    },
    gtm: {
      step: 5,
      list: 'Category',
      initOnly: true
    }
  },
  order: {
    name: 'shoppingcart',
    actions: {
      addItem: 'addItem'
    },
    showAlsoBought: false,
    isPreloaded: true,
    switchOrderOfferTitle: true,
    showActionButtons: false,
    showSummary: false,
    showItemDeliveryState: false,
    showItemQuantityButton: false,
    showItemActionButtons: false,
    itemDiscountWriteable: false
  }
}

export const COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP = {
  separator: {
    type: 'SeparatorCosts',
    component: 'summary-separator'
  },
  subtotal: {
    type: 'ProductCosts',
    component: 'summary-price'
  },
  additional: {
    type: 'AdditionalCosts',
    component: 'summary-price'
  },
  subtractional: {
    type: 'SubtractionalCosts',
    component: 'summary-price',
    attrs: {
      class: 'text-success'
    }
  },
  vat: {
    type: 'VatCosts',
    component: 'summary-price'
  },
  total: {
    type: 'TotalCosts',
    component: 'summary-price',
    attrs: {
      showCurrency: true,
      total: true
    }
  }
}

export const COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP = Object.keys(COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP).reduce(
  (types, tKey) =>
    Object.assign(types, {
      [COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP[tKey].type]: Object.assign({}, COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP[tKey], { type: tKey })
    }),
  {}
)

export const COMPONENT_PRODUCTDELIVERYSTATE_STATES = {
  available: {
    serverId: 'DeliveryStateAvailable',
    icon: 'deliverystate-available',
    variant: 'success'
  },
  some: {
    serverId: 'DeliveryStateSomeAvailable',
    icon: 'deliverystate-available',
    variant: 'success'
  },
  inweeks: {
    serverId: 'DeliveryStateAvailableInSomeWeeks',
    icon: 'deliverystate-inweeks',
    variant: 'warning'
  },
  in6to8weeks: {
    serverId: 'DeliveryStateAvailable6To8Weeks',
    icon: 'deliverystate-inweeks',
    variant: 'warning'
  },
  unavailable: {
    serverId: 'DeliveryStateUnavailable',
    icon: 'deliverystate-unavailable',
    variant: 'danger'
  },
  unknown: {
    serverId: 'DeliveryStateUnknown',
    icon: 'b-question-circle',
    variant: 'dark'
  }
}

export const PRODUCTDELIVERYSTATE_COMPONENT_STATES = Object.keys(COMPONENT_PRODUCTDELIVERYSTATE_STATES).reduce(
  (states, sKey) =>
    Object.assign(states, {
      [COMPONENT_PRODUCTDELIVERYSTATE_STATES[sKey].serverId]: Object.assign({}, COMPONENT_PRODUCTDELIVERYSTATE_STATES[sKey], { clientId: sKey })
    }),
  {}
)

export const COMPONENT_PRODUCTBUYBUTTON_DROPDOWN_MAX_ITEMS = 6
export const COMPONENT_PRODUCTBUYBUTTON_QUANTITY_DEFAULTS = {
  min: 1,
  max: 1000,
  step: 1,
  initialStep: 1
}

export const COMPONENT_SEARCH_TYPES = {
  product: {
    isDefault: true,
    endpoint: 'Products',
    paging: {
      page: 1,
      pageSize: 24,
      nextPageSize: 24
    },
    result: {
      type: 'product',
      cols: {
        xs: 1,
        sm: 1,
        md: 3,
        lg: 3,
        xl: 3
      },
      mapper: (r) => r
    },
    gtm: {
      list: 'Category'
    }
  },
  sale: {
    endpoint: 'Products',
    paging: {
      page: 1,
      pageSize: 24,
      nextPageSize: 24
    },
    result: {
      type: 'product',
      cols: {
        xs: 1,
        sm: 1,
        md: 3,
        lg: 3,
        xl: 3
      },
      mapper: (r) => r
    },
    gtm: {
      list: 'Sale'
    }
  },
  query: {
    endpoint: 'Products',
    paging: {
      page: 1,
      pageSize: 24,
      nextPageSize: 24
    },
    result: {
      type: 'product',
      cols: {
        xs: 1,
        sm: 1,
        md: 3,
        lg: 3,
        xl: 3
      },
      mapper: (r) => r
    },
    gtm: {
      list: 'Search Results'
    }
  }
}

export const COMPONENT_SEARCH_FILTER_TYPES = {
  text: {
    type: 'text',
    componentName: 'search-filter-text',
    controls: {
      text: {
        emptyValue: ''
      }
    }
  },
  multiselect: {
    type: 'multiselect',
    componentName: 'search-filter-multiselect',
    controls: {
      item: {
        emptyValue: false
      }
    }
  },
  price: {
    type: 'pricerange',
    componentName: 'search-filter-range',
    controls: {
      minValue: {
        emptyValue: null,
        unit: 'CHF'
      },
      maxValue: {
        emptyValue: null,
        unit: 'CHF'
      }
    }
  },
  metric: {
    type: 'metricrange',
    componentName: 'search-filter-range',
    controls: {
      minValue: {
        emptyValue: null,
        unit: 'cm'
      },
      maxValue: {
        emptyValue: null,
        unit: 'cm'
      }
    }
  },
  weight: {
    type: 'weightrange',
    componentName: 'search-filter-range',
    controls: {
      minValue: {
        emptyValue: null,
        unit: 'kg'
      },
      maxValue: {
        emptyValue: null,
        unit: 'kg'
      }
    }
  },
  colors: {
    type: 'colors',
    componentName: 'search-filter-colors',
    controls: {
      item: {
        emptyValue: false
      }
    }
  },
  categories: {
    type: 'categories',
    componentName: 'search-filter-hidden',
    controls: {
      hidden: {
        emptyValue: ''
      }
    }
  },
  unknown: {
    type: 'unknown',
    componentName: 'search-filter-unknown'
  }
}

export const COMPONENT_SEARCH_FILTER_MAPPER_TYPES_MAP = {
  text: ['text'],
  items: ['multiselect', 'colors'],
  range: ['pricerange', 'metricrange', 'weightrange'],
  hidden: ['categories']
}

export const COMPONENT_SEARCH_FILTER_URL_PARAMETER_SEPARATOR = ';'

export const COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP = {
  input: {
    type: 'StringValueAttribute',
    component: 'control-input',
    baseValue: ''
  },
  integer: {
    type: 'IntValueAttribute',
    component: 'control-input',
    baseValue: ''
  },
  select: {
    type: 'SingleSelectValueAttribute',
    component: 'control-select',
    baseValue: ''
  },
  radio: {
    type: 'SingleSelectRadioValueAttribute',
    component: 'control-radio',
    baseValue: ''
  },
  singlerelation: {
    type: 'SingleRelationValueAttribute',
    component: 'control-select',
    baseValue: ''
  },
  textarea: {
    type: 'MultiLineStringValueAttribute',
    component: 'control-textarea',
    baseValue: ''
  },
  checkbox: {
    type: 'MultiSelectValueAttribute',
    component: 'control-checkbox',
    baseValue: []
  },
  multirelation: {
    type: 'MultiRelationValueAttribute',
    component: 'control-multiselect',
    baseValue: []
  },
  password: {
    type: 'PasswordValueAttribute',
    component: 'control-input',
    baseValue: '',
    controlType: 'password',
    validators: ['requiredValidator'],
    /*
      https://docs.microsoft.com/en-us/aspnet/core/security/authentication/identity-configuration?view=aspnetcore-5.0#password
      Passord Regex:
      ^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]))([a-zA-Z\d]|[^a-zA-Z\d\s]){6,}$
      1 lowercase
      1 uppercase
      1 digit
      1 nonalphanumeric => ~`!@#$%^&*()_-+={}[]|\:;<,>.?/
        but no space-characters \s => \s\t\v\r\n\f
      6 digits or longer
    */
    customValidators: {
      lowercase: {
        validation: (value) => /^(?=.*[a-z])/.test(value)
      },
      uppercase: {
        validation: (value) => /^(?=.*[A-Z])/.test(value)
      },
      digit: {
        validation: (value) => /^(?=.*\d)/.test(value)
      },
      nonealphanumeric: {
        validation: (value) => /^(?=.*\W)/.test(value)
      },
      minLength: {
        validation: validators.minLength,
        parameters: 6
      }
    }
  },
  passwordConfirm: {
    type: 'CustomPasswordValueAttribute',
    component: 'control-input',
    baseValue: '',
    controlType: 'password'
    // Do this in your View and return the property you want to check with
    // import { getNamedParentContext } from '@/assets/js/helper/vm'
    // customValidators: {
    //   sameAsPassword: {
    //     validation: validators.sameAs,
    //     parameters (control = {}) {
    //       const context = getNamedParentContext('Formular', this)
    //       // if context is null, return either null (validation returns false), or control.value (validation returns true)
    //       return context ? context.form.controls.password.control.value : control.value
    //     }
    //   }
    // }
  },
  files: {
    type: 'MultiFileValueAttribute',
    component: 'control-files',
    baseValue: [],
    requiresHardChange: true
  },
  boolean: {
    type: 'BooleanValueAttribute',
    component: 'control-checkbox',
    baseValue: false
  },
  autocomplete: {
    type: 'AutoCompleteValueAttribute',
    component: 'control-autocomplete',
    baseValue: ''
  },
  unknown: {
    type: 'UnknownValueAttribute',
    component: 'control-unknown',
    baseValue: ''
  }
}

export const COMPONENT_CONTROL_DISCRIMINATOR_CONTROL_MAP = Object.keys(COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP).reduce(
  (types, tKey) =>
    Object.assign(types, {
      [COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP[tKey].type]: Object.assign({}, COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP[tKey], { type: tKey })
    }),
  {}
)

export const COMPONENT_CONTROL_CHECK_DISCRIMINATOR_MAP = {
  equal: {
    type: 'VisibleWhenEqual',
    check: (a, b) => a === b
  }
}

export const COMPONENT_CONTROL_DISCRIMINIATOR_CHECK_MAP = Object.keys(COMPONENT_CONTROL_CHECK_DISCRIMINATOR_MAP).reduce(
  (checks, cKey) =>
    Object.assign(checks, {
      [COMPONENT_CONTROL_CHECK_DISCRIMINATOR_MAP[cKey].type]: Object.assign({}, COMPONENT_CONTROL_CHECK_DISCRIMINATOR_MAP[cKey], { type: cKey })
    }),
  {}
)

export const COMPONENT_CONTROL_ENTITYPROPERTY_HIDE_BY_PROPERTIES = ['hidden']

// manage which entity properties can be placed beside each other
// given numbers will define grid-col-width
// if property "entityName" is present grouping will be applied only on the given entity
// otherwise grouping will be applied on every entity with a property match
export const COMPONENT_CONTROL_ENTITYPROPERTY_ROWGROUPS = [
  {
    entityName: 'User',
    firstname: 6,
    lastname: 6
  },
  {
    entityName: 'User',
    company: 6,
    language: 6
  },
  {
    entityName: 'User',
    email: 6,
    phone: 6
  },
  {
    entityName: 'User',
    phoneMobile: 6,
    phoneMobile2: 6,
    phoneBusiness: 6
  },
  {
    entityName: 'Location',
    name: 6,
    language: 6
  },
  {
    entityName: 'UserAddress',
    firstname: 6,
    lastname: 6
  },
  {
    entityName: 'UserAddress',
    company: 12
  },
  {
    street: 6,
    addition: 6
  },
  {
    zip: 4,
    city: 8
  }
]

export const COMPONENT_PRODUCTCONFIGURATOR_CONFIGURATION_TYPES = {
  text: {
    types: ['dropdown', 'buttons'],
    component: {
      summary: 'product-text',
      editor: 'product-configurator-editor-text',
      newSummary: 'new-product-text',
      newEditor: 'new-product-configurator-editor-text'
    },
    isDefault: true
  },
  color: {
    types: ['color'],
    component: {
      summary: 'product-color',
      editor: 'product-configurator-editor-color',
      newSummary: 'new-product-color',
      newEditor: 'new-product-configurator-editor-color'
    }
  }
}
